import React, { useEffect, useMemo } from "react";
import ExSide from "./components/ExSide";
import Header from "./HomeComp/Header";
import OrdersTab from "./components/Orders.tab";
import CandleGraph from "./components/CandleGraph";
import BuyNSell from "./components/BuyNSell";
import Preloader from "./components/PreLoader";
import TradeTab from "./Trade.Tab";
import OrderSystemTab from "./Order.system.tab";
import "./exchange.css";
import { useSelector } from "react-redux";
import Footer from "./HomeComp/Footer";

import { createSelector } from "reselect";
import { useParams, useHistory } from "react-router-dom";

const selectCoinDBReducer = (state) => state.coinDBReducer;
const selectAuthReducer = (state) => state.AuthReducer;
const selectWebsiteDBReducer = (state) => state.websiteDBReducer;

const makeSelectCoinDBReducer = createSelector(
  [selectCoinDBReducer],
  (coinDBReducer) => ({
    coins: coinDBReducer.coins,
    coins_loading: coinDBReducer.coins_loading,
    wallet_loading: coinDBReducer.wallet_loading,
    pending_order_loading: coinDBReducer.pending_order_loading,
    close_order_loading: coinDBReducer.close_order_loading,
    user_fav_pairing: coinDBReducer.user_fav_pairing,
    user_fav_loading: coinDBReducer.user_fav_loading,
    currency_graph: coinDBReducer.currency_graph,
    paired_curency_price: coinDBReducer.paired_curency_price,
    buy_order_book: coinDBReducer.buy_order_book,
    sell_order_book: coinDBReducer.sell_order_book,
    order_book_loading: coinDBReducer.order_book_loading,
    vhl_data: coinDBReducer.vhl_data,
    trade_history: coinDBReducer.trade_history,
    trade_loading: coinDBReducer.trade_loading,
    user_order_pending: coinDBReducer.user_order_pending,
    user_order_close: coinDBReducer.user_order_close,
  })
);

const makeSelectAuthReducer = createSelector(
  [selectAuthReducer],
  (AuthReducer) => ({
    isLoggedIn: AuthReducer.isLoggedIn,
    user: AuthReducer.user
  })
);

const makeSelectWebsiteDBReducer = createSelector(
  [selectWebsiteDBReducer],
  (websiteDBReducer) => ({
    webData: websiteDBReducer.webData,
  })
);

export default function Exchange(props) {
  const history = useHistory();

  const {
    coins,
    coins_loading,
    wallet_loading,
    pending_order_loading,
    close_order_loading,
    user_fav_pairing,
    user_fav_loading,
    currency_graph,
    vhl_data,
    buy_order_book,
    sell_order_book,
    order_book_loading,
    paired_curency_price,
    user_order_pending,
    user_order_close,
    trade_history,
    trade_loading
  } = useSelector(makeSelectCoinDBReducer);
  const { user, isLoggedIn } = useSelector(makeSelectAuthReducer);
  const { webData } = useSelector(makeSelectWebsiteDBReducer);

  const [loading, setLoading] = React.useState(true);

  const { id } = useParams()

  useEffect(() => {
    if (!id) {
      history.replace("/exchange/btc-inr");
    }
  }, [id, history]);

  const coin = useMemo(() => id?.split("-"), [id])
  const coinname = useMemo(() => coin[0] + coin[1], [coin]);


  useEffect(() => {
    let match = 1;
    const browser_currency_pair = props?.match?.params?.id.split("-");
    let vll = Object.values(coins);

    if (vll.length) {
      vll.forEach((item) => {
        // console.log("not inr",browser_currency_pair[1].toUpperCase(), item.symbol);
        if (item.symbol === browser_currency_pair[0].toUpperCase()) {
          if (browser_currency_pair[1].toUpperCase() !== "INR") {
            vll.forEach((it) => {
              if (it.symbol === browser_currency_pair[1].toUpperCase()) {
                match = 0;
              }
            });
          } else {
            match = 0;
          }
        }
      });
      if (match === 1) {
        props.history?.replace("/");
      }
      if (isLoggedIn) {
        if (
          !coins_loading &&
          !wallet_loading &&
          !pending_order_loading &&
          !close_order_loading &&
          !user_fav_loading
        )
          document.title =
            props?.match?.params?.id?.toUpperCase() +
            " " +
            webData.website_title +
            " Exchange";
        setLoading(false);
      } else {
        console.log('not logged in')
        console.log(coins_loading)
        if (coins_loading == false) setLoading(false);
      }
    }
  }, [coins_loading, wallet_loading, coins.length, user_fav_loading]);



  return (
    <>
      <Header {...props} />

      {loading ? (
        <Preloader />
      ) : (
        <div className="row exchange_styled">
          <div className="col-12 col-md-7 col-lg-8 ps-1 pe-1 py-0 mt-2">
            <CandleGraph props={props} id={id} coins={coins} currency_graph={currency_graph} user_fav_pairing={user_fav_pairing} vhl_data={vhl_data} isLoggedIn={isLoggedIn} user={user} coin={coin} />
            <OrdersTab isLoggedIn={isLoggedIn} user={user} user_order_pending={user_order_pending} user_order_close={user_order_close} />
          </div>

          <div className="col-12 col-md-3 col-lg-4 mt-2">
            <div className="row">
              <div className="col-12 col-lg-6 col-md-4 px-1 py-0 ">
                <OrderSystemTab id={id} coinname={coinname} coin={coin} coins={coins} paired_curency_price={paired_curency_price} buy_order_book={buy_order_book} sell_order_book={sell_order_book} order_book_loading={order_book_loading} vhl_data={vhl_data} />
              </div>

              <div className="col-12 col-lg-6 col-md-4 ps-1 py-0">
                <TradeTab {...props} coin={coin} coinname={coinname} trade_history={trade_history} trade_loading={trade_loading} webData={webData} />
              </div>
            </div>

            <div className="row">
              <BuyNSell {...props} />
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}
