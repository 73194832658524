import React, { useEffect, useState } from 'react';


const MyPNL = ({ totalDeposit, totalWithdraw, availableBalance, total }) => {
 
  

  

  return (
    <div className="account_page">
      <div className="page-content-title">
        <h3 className="text-center">My PNL</h3>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="d-flex flex-wrap justify-content-center">
            
            <div className="card box_shadow m-2" style={{ flex: '1 1 45%', minWidth: '250px' }}>
              <div className="card-body">
                <div className="d-flex justify-content-between my-3">
                  <span>Total Deposit</span>
                  <span>≈ {totalDeposit} INR</span>
                </div>
              </div>
            </div>
            
            <div className="card box_shadow m-2" style={{ flex: '1 1 45%', minWidth: '250px' }}>
              <div className="card-body">
                <div className="d-flex justify-content-between my-3">
                  <span>Total Withdrawal</span>
                  <span>≈ {totalWithdraw} INR</span>
                </div>
              </div>
            </div>
            
            <div className="card box_shadow m-2" style={{ flex: '1 1 45%', minWidth: '250px' }}>
              <div className="card-body">
                <div className="d-flex justify-content-between my-3">
                  <span>Total Available Balance</span>
                  <span>≈ {availableBalance} INR</span>
                </div>
              </div>
            </div>
            
            <div className="card box_shadow m-2" style={{ flex: '1 1 45%', minWidth: '250px' }}>
              <div className="card-body">
                <div className="d-flex justify-content-between my-3">
                  <span>PNL</span>
                  <span className={total > 0 ? "text-success" : "text-danger"}>
                    ≈ {total > 0 ? "+ " : ""}{total} INR
                  </span>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPNL;
