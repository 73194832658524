import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./chart.css";
import { toggleFav } from "../redux/actions/coinDBAction";
import TVChartContainer from "./CandleChart";
import ExSide from "./ExSide";
import { getRound } from "../redux/helpers/helper_functions";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";


export default function CandleGraph({
  props,
  id,
  coins,
  currency_graph,
  user_fav_pairing,
  vhl_data,
  user,
  isLoggedIn,
  coin
}) {
  const filter = "1h";
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true)
  const [state, setState] = useState({
    isFav: 0,
    current_price: [],
    volume: 0,
    high: 0,
    low: 0,
    newgetchart: false,
    prev_symbol: '',
    isSell: ''
  });

  const updateIsFav = useCallback((match) => {
    setState((prevState) => ({
      ...prevState,
      isFav: match,
    }));
  }, []);

  const updatePrevSymbol = useCallback((symbol) => {
    setState((prevState) => ({
      ...prevState,
      prev_symbol: symbol,
    }));
  }, []);

  const updateChart = useCallback((chart) => {
    setState((prevState) => ({
      ...prevState,
      newgetchart: chart,
    }));
  }, []);

  const updateState = useCallback((updates) => {
    setState((prevState) => ({
      ...prevState,
      ...updates,
    }));
  }, []);

  const data = useMemo(() => {
    return Object.values(coins).find((d) => d.symbol === coin[0].toUpperCase());
  }, [coins, coin]);

  useEffect(() => {
    const match = user_fav_pairing.includes(data?.symbol.toUpperCase());
    updateIsFav(match)
  }, [user_fav_pairing, data]);

  useEffect(() => {
    if (coins && currency_graph && user_fav_pairing && vhl_data) {
      setLoading(false);
    }
  }, [coins, currency_graph, user_fav_pairing, vhl_data]);

  useEffect(() => {
    const matchingKey = vhl_data[id];
    if (vhl_data && matchingKey) {
      const matchingData = vhl_data[id];

      if (matchingData) {
        const isSell = state.current_price >= matchingData.raw_price ? "up" : "down";
        updateState({
          isSell,
          volume: matchingData.volume,
          high: matchingData.high,
          low: matchingData.low,
          percet: matchingData.avg,
          current_price: matchingData.raw_price,
        });
      }
    } else {
      updateState({
        volume: 0,
        high: 0,
        low: 0,
        percet: 0,
        current_price: data.current_price ? data.current_price : 1,
      });
    }
  }, [vhl_data, id, state.current_price, data.current_price, updateState]);

  function getChart(symbol, symbol2) {
    updatePrevSymbol(symbol);
    return (
      <>
        <TVChartContainer symbols={symbol} pre_symbols={symbol2} />
      </>
    );
  }

  useEffect(() => {
    let coinsym = coin[0] + "-" + coin[1];
    updateChart(getChart(coinsym, state.prev_symbol))
  }, [...coin, filter, currency_graph]);

  return (
    <>
      <div class="card">
        <div class="card-body p-0">
          <div class="currency-market">
            <div class="currency-header-left currency_header">
              <div class="currency-header-left-inner" id="hide_section">
                <div class="d-flex">
                  <div>
                    <img
                      className="me-2 position-relative top9 market_coin_img"
                      src={data?.icon}
                      alt=""
                    />
                  </div>
                  <div>
                    <a
                      class="fs-16"
                      data-bs-toggle="modal"
                      data-bs-target="#currency_price_modal"
                      href="/exchange/btc-inrx"
                    >
                      {id.toUpperCase().replace("-", "/")}{" "}
                      <span className="small text-muted ms-2">
                        ({data?.name}){" "}
                      </span>
                    </a>
                  </div>
                </div>
                <div class="arrow_down"></div>
              </div>

              <div class="hide-currency-market">
                <div class="exchange-pairs">
                  <div class="">
                    <ExSide {...props} />
                  </div>
                </div>
              </div>
            </div>
            <div class="currency-header-right">
              <div className="currency-header-right-inner">
                <div class="value-block">
                  <span class="heading">Last Price</span>
                  <span class={`${state.isSell === "up" ? "text-green" : "text-red"} heading_value`}>{parseFloat(state.current_price)}{state.isSell === "up" ? <BsArrowUpShort size={18} className="mx-2" /> : <BsArrowDownShort size={18} className="mx-2" />}</span>
                  <span
                    onClick={() => {
                      dispatch(
                        toggleFav(
                          user?.params ? user.params.user_id : user.user_id,
                          (data?.symbol).toUpperCase()
                        )
                      );
                      if (isLoggedIn) updateIsFav(!state.isFav);
                    }}
                  ></span>
                </div>

                <div className="value-block">
                  <span className="heading">Volume</span>
                  <span className="heading_value">{getRound(state.volume)}</span>
                </div>
                <div className="value-block">
                  <span className="heading">High</span>
                  <span className="heading_value">
                    <span class="high_24h">{state.high}</span>
                  </span>
                </div>
                <div className="value-block">
                  <span className="heading">Low</span>
                  <span className="heading_value">
                    <span class="low_24h">{state.low}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="sc-bdVaJa sc-kUaPvJ kZeBBS row">
            <div className="sc-bdVaJa sc-giadOv iIMfMq col-12 col-md-8 col-lg-8"></div>
          </div>
          <div id="candleCart1">{state.newgetchart}</div>
        </div>
      </div>
    </>
  );
}