import { AiOutlinePieChart } from "react-icons/ai";
import { CiLock } from "react-icons/ci";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";

function FeatureCard({ CardNumber, CardIcon, CardTitle, CardDescription }) {
  return (
    <div className="cardL text-white px-4 py-4">
      <div className="d-flex justify-content-center">
        <div className="card-icon">{CardIcon}</div>
      </div>
      <div className="card-body d-flex justify-content-center flex-column align-items-center">
        <h5 className="card-title">{CardTitle}</h5>
        <p className="card-text">{CardDescription}</p>
      </div>
    </div>
  );
}

export default function Features() {
  return (
    <div className="mt-5">
      <div className="container">
        <div className="row">
          <div className="text-white col order-2 order-lg-1">
            <div className="container">
              <div className="row">
                {/* cardLs  */}
                {/* card number 1 */}
                <div className="col">
                  <FeatureCard
                    CardIcon={
                      <AiOutlinePieChart
                        className="card-icon-color1"
                        size={45}
                      />
                    }
                    CardTitle={"Portfolio Manager"}
                    CardDescription={
                      "Buy and sell popular digital currencies. Keep track of them in one place."
                    }
                  />
                </div>
                {/* card number 2 */}
                <div className="col">
                  <FeatureCard
                    CardIcon={<CiLock className="card-icon-color2" size={45} />}
                    CardTitle={"Vault Protection"}
                    CardDescription={
                      "For added security, store your funds in a vault with time delayed withdrawals."
                    }
                  />
                </div>
                <div className="col-lg-6 offset-lg-3">
                  {/* card number 3 */}
                  <FeatureCard
                    CardIcon={
                      <HiOutlineDevicePhoneMobile
                        className="card-icon-color3"
                        size={45}
                      />
                    }
                    CardTitle={"Mobile Apps"}
                    CardDescription={
                      "Stay on top of the markets with the DTBX app for Android or iOS"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-white col order-1 order-lg-2 d-flex flex-column align-items-center justify-content-center">
            <h1 className="fw-bold display-2 mb-4 text-center">
              The Most Trusted Cryptocurrency Platform
            </h1>
            <p className="text-center mb-4">
              DTBX has a variety of features that make it the best place to
              start trading.
            </p>
            {/* <button className="btn btn-lg button-blue-rounded w-75 text-white p-3 fw-bold px-lg-5">
              Let's Trade Now
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
