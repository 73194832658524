import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import Carousel from "react-grid-carousel";
import { FaCoins, FaInstagram, FaFacebook } from "react-icons/fa";
import { BiGitCompare } from "react-icons/bi";
import { HiCubeTransparent } from "react-icons/hi";
import { FiUser, FiGlobe, FiUsers } from "react-icons/fi";
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from "react-icons/io5";

import Header from "./Header";
import Footer from "./Footer";
import { N_getRefferalData } from "../redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
import { Helmet } from "react-helmet";
import { FacebookShareButton, TwitterShareButton, EmailShareButton, TwitterIcon, FacebookIcon, LinkedinIcon, TelegramIcon, WhatsappIcon, LineShareButton, TelegramShareButton, WhatsappShareButton, LinkedinShareButton } from 'react-share';
import { BsTwitterX } from "react-icons/bs";




const copyToClipboard = (text) => {
  if (!text) return; // Don't copy if the text is empty
  navigator.clipboard.writeText(text).then(
    () => {
      NotificationManager.info("Copy to clipboard");
    },
    (err) => {
      console.error("Failed to copy: ", err);
      NotificationManager.error("Copy Failed");
    }
  );
};

export default function AffiliateHome(props) {
  const { user, profile, isLoggedIn } = useSelector(
    (state) => state.AuthReducer
  );
  const [referralKey, setReferralKey] = useState(null);
  const [referralUserData, setReferralUserData] = useState(null);

  const handleCopyClick = (key) => {
    copyToClipboard(key);
  };

  useEffect(() => {
    if (isLoggedIn) {
      N_getRefferalData(user?.params ? user.params.token : user.token).then(
        (d) => {
          if (d.status) {
            const key = d.params.referral_code;
            if (key) setReferralKey(key);
            const total_ref = d.params.total_referals;

            if (total_ref && total_ref.length > 0) {
              setReferralUserData(total_ref);
            }
          }
        }
      );
    }
  }, []);

  return (
    <>
      <div className="application">
        <Helmet>
          <meta
            name="description"
            content="Join DTBX Affiliate Program for exclusive rewards. Refer friends and earn
exciting benefits. Elevate your crypto journey with DTBX."
          />
          <title>Earn Free Crypto with DTBX Referral Program</title>
        </Helmet>
      </div>
      <Header />
      <div className="page-content">
        <div className="tab_area">
          <div className="container custom_pills">
            <div className="nav nav-pills" id="nav-tab" role="tablist">
              {/* <div
                className="nav-item nav-link fs-16 me-4 "
                href="#reward_hub"
                data-toggle="tab"
              >
                Earn Together With Your Friends
              </div> */}
              <div
                className="nav-item nav-link fs-16 active"
                href="#affiliate_prog"
                data-toggle="tab"
              >
                Affiliate Program
              </div>
            </div>
          </div>
        </div>

        <div className="tab-content">
          {/* <div
            className="tab-pane fade active show"
            data-toggle="tab"
            id="reward_hub"
          >
            <div className="reward_banner page_spacer">
              <div className="container">

              </div>
            </div>
          </div> */}
          {/* ------------------------------------------------------------------- */}
          <div className="tab-pane active show" data-toggle="tab" id="affiliate_prog">
            <div className="inner_page_banner">
              {/* <div className="banner_left"></div>
      <div className="banner_right"></div> */}
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-12 col-lg-6 mb-3">
                    <div className="banner_left_inner">
                      <h2 className="maintxt">
                        <span className="text-pink"> Earn </span> Together With
                        Your Friends
                      </h2>

                      <div className="col-lg-6">
                        <img src="/img/tokenomics.png" alt=""></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div class="buysell-card mt-2 mb-2">
                      <div class="p-4">
                        <div className="row mb-4">
                          <div className="col-lg-6">
                            <div>
                              {" "}
                              <span className="small-info">
                                Your Commission Rate :
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div>
                              {" "}
                              <span className="small-info">
                                Invitee's Cashback Rate:
                              </span>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              value={referralKey ? referralKey : ""}
                              placeholder="Limit: 10 - 5,000"
                            />
                            {referralKey && (
                              <>
                                <span
                                  className="input-group-text"
                                  onClick={() => {
                                    handleCopyClick(referralKey);
                                  }}
                                >
                                  <i className="fa fa-copy fs-5 text-muted cursor-pointer"></i>
                                </span>

                              </>
                            )}
                          </div>
                        </div>


                        <div>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              value={
                                referralKey
                                  ? "https://dtbx.exchange/create/" +
                                  referralKey
                                  : ""
                              }
                              placeholder="Limit: 10 - 5,000"
                            />
                            {referralKey && (
                              <span
                                className="input-group-text"
                                onClick={() => {
                                  const key =
                                    "https://dtbx.exchange/create/" +
                                    referralKey;
                                  handleCopyClick(key);
                                }}
                              >
                                <i className="fa fa-copy fs-5 text-muted cursor-pointer"></i>
                              </span>
                            )}
                          </div>
                          {isLoggedIn && (
                            <div className="">
                              <div className="d-flex justify-content-center align-items-center">
                                <FacebookShareButton url={`https://dtbx.exchange/create/${referralKey}`}>
                                  <FacebookIcon className="p-2" />
                                </FacebookShareButton>
                                <LinkedinShareButton url={`https://dtbx.exchange/create/${referralKey}`}>
                                  <LinkedinIcon className="p-2" />
                                </LinkedinShareButton>
                                <TelegramShareButton url={`https://dtbx.exchange/create/${referralKey}`}>
                                  <TelegramIcon className="p-2" />
                                </TelegramShareButton>
                                <WhatsappShareButton url={`https://dtbx.exchange/create/${referralKey}`}>
                                  <WhatsappIcon className="p-2" />
                                </WhatsappShareButton>
                                <TwitterShareButton url={`https://dtbx.exchange/create/${referralKey}`}>
                                  <TwitterIcon className="p-2" />
                                </TwitterShareButton>
                              </div>
                            </div>
                          )}
                        </div>

                        {!isLoggedIn ? (
                          <div class="d-grid mt-2">
                            <button
                              class="btn btn-success btn-lg"
                              onClick={() => {
                                props.history.replace("/login");
                              }}
                            >
                              Sign UP / Log In
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {referralUserData && referralUserData.length > 0 ? (
                    <>
                      <div className="col-lg-12 mt-5">
                        <div className="mb-4 text-center">
                          <div className="">
                            <div class="">
                              <h4 className="">Referral Users</h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="overflow-auto mt-3">
                        <div className="table_scroll_div">
                          <table className="table global_table">
                            <thead>
                              <tr className="text-center">
                                <th>No.</th>
                                <th>Wallet Type</th>
                                <th>From</th>
                                <th>Name</th>
                                <th>Volume</th>
                                <th>Time</th>
                                <th>Email Status</th>
                                <th>KYC Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {referralUserData && referralUserData.length > 0
                                ? referralUserData.map((item, key) => {
                                  return (
                                    <tr className="text-center">
                                      <td>{key + 1}</td>
                                      <td>{item.wallet_type}</td>
                                      <td>{item.from_id}</td>
                                      <td>
                                        {item.name.length > 0 ? (
                                          item.name
                                        ) : (
                                          <span>{"Not Available"}</span>
                                        )}
                                      </td>
                                      <td>{item.valume}</td>
                                      <td>
                                        {new Date(
                                          Number(item.time)
                                        ).toLocaleString()}
                                      </td>
                                      <td>
                                        {!item.is_email_verified == 0 ? (
                                          <IoCheckmarkCircleOutline
                                            className="text-success"
                                            style={{
                                              width: "25px",
                                              height: "25px",
                                            }}
                                          />
                                        ) : (
                                          <IoCloseCircleOutline
                                            className="text-danger"
                                            style={{
                                              width: "25px",
                                              height: "25px",
                                            }}
                                          />
                                        )}
                                      </td>
                                      <td>
                                        {!item.kyc_status == 0 ? (
                                          <IoCheckmarkCircleOutline
                                            className="text-success"
                                            style={{
                                              width: "25px",
                                              height: "25px",
                                            }}
                                          />
                                        ) : (
                                          <IoCloseCircleOutline
                                            className="text-danger"
                                            style={{
                                              width: "25px",
                                              height: "25px",
                                            }}
                                          />
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="row align-items-center">
                  <div className="col-12 col-lg-7 mb-3">
                    <div className="banner_left_inner">
                      <h2 className="bannertxt">
                        <span className="text-pink">DTBX</span> Affiliate
                        Program
                      </h2>
                      <p className="mb-4">
                        {" "}
                        Welcome to the DTBX Affiliate Program. Refer friends to
                        earn four type of income. <br />
                        1) Signup referral income
                        <br />
                        2) KYC update income
                        <br />
                        3) Trade income upto 20%
                        <br />
                        4) Staking referral income
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-lg-5">
                    <img
                      src="/img/steps.png"
                      className="img-fluid"
                      width="80% "
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="spacer ">
              <div className="container">
                <div class="row align-items-center">
                  <div class="col-lg-4 col-md-6 col-12 d-flex justify-content-center ">
                    <img src="/img/pngwing.com-1.png" class="img-fluid" />
                  </div>
                  <div class="col-lg-8 col-md-6 mb-5">
                    <h2 class="para_heading">
                      {" "}
                      The Highest Commissions in the Industry{" "}
                    </h2>

                    <div className="row mt-5">
                      <div className="col-lg-4">
                        <FiUsers className="fs-3 mb-4 text-lgray" />
                        <h3>5,000+</h3>
                        <p className="lead text-lgray">Number of Affiliate</p>
                      </div>
                      <div className="col-lg-4">
                        <HiCubeTransparent className="fs-3 mb-4 text-lgray" />
                        <h3>1,00,000+ </h3>
                        <p className="lead text-lgray">Commission Paid Out </p>
                      </div>
                      <div className="col-lg-4">
                        <FiGlobe className="fs-3 mb-4 text-lgray" />
                        <h3>5+</h3>
                        <p className="lead text-lgray">Countries</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="page_spacer">
              <div className="container">
                <div className="para_heading text-center">
                  How to Earn Commissions
                </div>
                <div className="row d-flex align-center justify-content-center mt-5">
                  <div className="col-lg-4 col-md-6 col-12 padding_0">
                    <div className="card bg-transparent border-0 text-center">
                      <div className="card-body padding_5">
                        <div class="text-center mb-3">
                          <div class="vtimeline_no active"> 1</div>
                          <div class="vtimeline_right"></div>
                        </div>

                        <h4 className="">Apply to become an affiliate</h4>
                        <p className="text-lgray">
                          Anyone with community, media, or other resources can
                          apply to become a DTBX User.He will became as
                          affiliate.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 padding_0">
                    <div className="card bg-transparent border-0 text-center">
                      <div className="card-body padding_5">
                        <div class="text-center mb-3">
                          <div class="vtimeline_left"></div>
                          <div class="vtimeline_no "> 2</div>
                          <div class="vtimeline_right"></div>
                        </div>

                        <h4>Promote DTBX</h4>
                        <p className="text-lgray">
                          Share your referral link to your community, followers,
                          or other channels.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 padding_0">
                    <div className="card bg-transparent border-0 text-center">
                      <div className="card-body padding_5">
                        <div class="text-center mb-3">
                          <div class="vtimeline_left"></div>
                          <div class="vtimeline_no "> 3</div>
                        </div>

                        <h4>Claim commission</h4>
                        <p className="text-lgray">
                          You will earn commission when users who signed up ,
                          staking, trade, kyc via your referral link.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="features_section ">
              <div className="container">
                <div class="row align-items-center">
                  <div class="col-lg-4 col-md-6 col-12 d-flex justify-content-center ">
                    <img src="/img/phone-1.png" class="img-fluid" />
                  </div>
                  <div class="col-lg-8 col-md-6 mb-5">
                    <h2 class="para_heading">
                      {" "}
                      How much commission can I earn?{" "}
                    </h2>
                    <p className="lead text-lgray my-5">
                      When invitees conduct spot trading or futures trading on
                      DTBX, you will receive up to 20% commission from their
                      trading fees
                      <br />
                      1) User KYC refferal Income 8 DTBX.
                      <br />
                      2) Staking Refferal Income
                      <br />
                      &nbsp;&nbsp;a)3 month staking refferal income .75%
                      <br />
                      &nbsp;&nbsp;b)6 month staking refferal income 1.8%
                      <br />
                      &nbsp;&nbsp;c)12 month staking refferal income 4.2%Pp
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="spacer">
              <div className="container">
                <div className="para_heading text-center">
                  Why become a DTBX affiliate?
                </div>
                <div className="row d-flex align-center justify-content-center mt-5">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="card bg-transparent border-0 mb-4">
                      <div className="card-body padding_5">
                        <h4>High Commissions</h4>
                        <p className="text-lgray">
                          Daily commissions of up to 20% of trading fees, and
                          permanent affiliate relationships.
                        </p>
                      </div>
                    </div>
                    <div className="card bg-transparent border-0 mb-4">
                      <div className="card-body padding_5">
                        <h4 className="">Premium Brand</h4>
                        <p className="text-lgray">
                          With the goal of facilitating the free flow of digital
                          assets across the globe, DTBX is a premium brand that
                          constantly attracts new users into the cryptocurrency
                          space.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="card bg-transparent border-0 mb-4">
                      <div className="card-body padding_5">
                        <h4>Transparent Referral System</h4>
                        <p className="text-lgray">
                          Our visualized referral dashboard provides affiliates
                          with comprehensive and multi-channel commission
                          management.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container faq">
              <div className="col-lg-12 text-center">
                <h2 class="para_heading"> FAQs </h2>
              </div>
              <div className="col-lg-12">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        What is a referral program?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        A referral program is a marketing strategy where
                        existing customers or users recommend a product or
                        service to others in exchange for rewards or benefits.
                        It is a way for businesses to leverage word-of-mouth
                        marketing and incentivize their existing customer base
                        to bring in new customers.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        How does a referral program work?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        In a referral program, existing customers, often
                        referred to as referrers, are given a unique referral
                        link or code. They share this link with their friends,
                        family, or contacts who might be interested in the
                        product or service. When a new customer, referred to as
                        the referee, makes a purchase or signs up using the
                        referral link, both the referrer and the referee
                        typically receive rewards, such as discounts, credits,
                        or other incentives.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        What are the benefits of participating in a referral
                        program?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Participating in a referral program offers several
                        benefits. For referrers, it can result in receiving
                        discounts, credits, or other perks for each successful
                        referral. Referees, on the other hand, may enjoy special
                        introductory offers or discounts when they sign up
                        through a referral link. Overall, it fosters customer
                        loyalty, drives new customer acquisition, and creates a
                        positive cycle of word-of-mouth advertising.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingfour">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapsefour"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        How can I join a referral program?
                      </button>
                    </h2>
                    <div
                      id="flush-collapsefour"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingfour"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        To join a referral program, you typically need to be an
                        existing customer of the product or service. Look for
                        information about the referral program on the company's
                        website or within the product/service platform. You may
                        need to sign in to your account to access your unique
                        referral link or code. Follow the provided instructions
                        to start referring friends and earning rewards.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingfive">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapsefive"
                        aria-expanded="false"
                        aria-controls="flush-collapsefive"
                      >
                        What are common types of rewards in referral programs?
                      </button>
                    </h2>
                    <div
                      id="flush-collapsefive"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingfive"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Rewards in referral programs vary but often include
                        discounts on future purchases, credits applied to
                        accounts, free products or services, or exclusive access
                        to special promotions. The specific rewards depend on
                        the company's referral program policies.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingsix">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapsesix"
                        aria-expanded="false"
                        aria-controls="flush-collapsesix"
                      >
                        Can I refer multiple people, and are there any
                        restrictions?
                      </button>
                    </h2>
                    <div
                      id="flush-collapsesix"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingsix"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Yes, referral programs often allow referrers to
                        recommend multiple people. However, there may be limits
                        or restrictions, such as a maximum number of referrals
                        per month or rules against self-referrals. Refer to the
                        terms and conditions of the referral program for
                        details.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingseven">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseseven"
                        aria-expanded="false"
                        aria-controls="flush-collapseseven"
                      >
                        How do I track the status of my referrals?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseseven"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingseven"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Most referral programs provide a dashboard or section
                        within your account where you can track the status of
                        your referrals. This includes information on who has
                        signed up or made a purchase using your referral link,
                        as well as the rewards you've earned. If you have
                        trouble finding this information, reach out to customer
                        support for assistance.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingeigth">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseeight"
                        aria-expanded="false"
                        aria-controls="flush-collapseeight"
                      >
                        Is there an expiration date for my referral credits or
                        rewards?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseeight"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingeight"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Referral program terms may include expiration dates for
                        earned rewards. It's essential to check the terms and
                        conditions of the program to understand any time
                        limitations on the usability of your referral credits or
                        rewards.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingnine">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapsenine"
                        aria-expanded="false"
                        aria-controls="flush-collapsenine"
                      >
                        Can I participate in a referral program as a new
                        customer?
                      </button>
                    </h2>
                    <div
                      id="flush-collapsenine"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingnine"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Yes, referral programs are often open to both existing
                        customers (referrers) and new customers (referees). New
                        customers can usually sign up through a referral link
                        provided by an existing customer to access special
                        offers or discounts.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingten">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseten"
                        aria-expanded="false"
                        aria-controls="flush-collapseten"
                      >
                        What should I do if I have issues with the referral
                        program or rewards?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseten"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingten"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        If you encounter any issues with the referral program or
                        have questions about your rewards, contact the customer
                        support team of the company running the program. They
                        should be able to assist you with any concerns or
                        inquiries you may have.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

      <Footer />
    </>
  );
}
