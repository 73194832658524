import React, { useState } from 'react'
import Header from './HomeComp/Header'
import ProfileSidebar from './components/ProfileSidebar'

function ChatSupport(props) {
    const fakeChats = [
        { id: 1, sender: 'Customer Support', message: 'Hello, how can I assist you today?' },
        { id: 2, sender: 'User', message: 'Hi, Im experiencing issues with my account.' },
        { id: 3, sender: 'Customer Support', message: 'Sure, Ill help you with that. Can you please provide your account details?' },
        // Add more fake chat messages as needed
    ];

    // State to track the attached file
    const [attachedFile, setAttachedFile] = useState(null);
    const [image, setImage] = useState(null)


    // Function to handle file attachment
    // Function to handle file attachment
    const handleFileAttach = (event) => {
        const file = event.target.files[0];
        setAttachedFile(file);
        setImage(URL.createObjectURL(event.target.files[0]));

    };
    return (
        <>
            <Header />

            <div className="s-layout">
                <div class="s-layout__sidebar">
                    <ProfileSidebar />
                </div>

                <div className="s-layout__content">
                    <div className="dashboard_header">
                        <h3 className='header_title'>Chat Support</h3>
                    </div>
                </div>


                <div>
                    <section style={{ backgroundColor: '#000' }}>
                        <div className="container py-5">
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-10 col-lg-8 col-xl-6">
                                    <div className="card" id="chat2">
                                        <div className="card-header d-flex justify-content-between align-items-center p-3">
                                            <h5 className="mb-0">Chat</h5>
                                            <button type="button" className="btn btn-primary btn-sm" data-mdb-ripple-color="dark">
                                                Let's Chat App
                                            </button>
                                        </div>
                                        <div
                                            className="card-body"
                                            data-mdb-perfect-scrollbar="true"
                                            style={{ position: 'relative', height: '400px', overflowY: 'scroll' }}
                                        >
                                            {/* Display fake chat messages */}
                                            {fakeChats.map(chat => (
                                                <div key={chat.id} className={chat.sender === 'Customer Support' ? 'd-flex flex-row justify-content-start' : 'd-flex flex-row justify-content-end mb-4 pt-1'}>
                                                    <div>
                                                        <p className={chat.sender === 'Customer Support' ? 'small p-2 ms-3 mb-1 rounded-3 bg-danger' : 'small p-2 ms-3 mb-1 rounded-3 bg-primary'}>{chat.message}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="card-footer text-muted d-flex justify-content-start align-items-center p-3">
                                            <img
                                                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3-bg.webp"
                                                alt="avatar 3"
                                                style={{ width: '40px', height: '100%' }}
                                            />
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="exampleFormControlInput1"
                                                placeholder="Type message"
                                            />
                                            <label htmlFor="fileInput" className="ms-1 text-muted">
                                                <i className="fas fa-paperclip" style={{ cursor: "pointer" }}></i>
                                            </label>
                                            <input
                                                type="file"
                                                id="fileInput"
                                                style={{ display: 'none' }}
                                                onChange={handleFileAttach}
                                            />
                                            <a className="ms-3 text-muted" href="#!">
                                                <i className="fas fa-smile"></i>
                                            </a>
                                            <a className="ms-3" href="#!">
                                                <i className="fas fa-paper-plane"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default ChatSupport