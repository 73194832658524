import { IoLogoFacebook, IoLogoInstagram, IoLogoYoutube } from "react-icons/io5";
import LogoImage from "../../public/main.png";
import MSMECertificate from "../../public/MSMECertificate.pdf";
import UKCertificateofIncorporation from "../../public/UKCertificateofIncorporation.pdf";
import CertificateofIncorporation from "../../public/CertificateofIncorporation.pdf";
import GSTCertificate from "../../public/GSTCertificate.pdf";
import CentralKYCRegistrationCertificate from "../../public/Central-KYC-Registration-Certificate.pdf";
import { AiFillTwitterCircle } from "react-icons/ai";
import { BsFacebook, BsInstagram, BsLinkedin, BsTelegram, BsYoutube } from "react-icons/bs";
import { IoDocumentAttach } from "react-icons/io5";

export default function FooterNav() {
  return (
    <div className="mt-5">
      <div className="container border-bottom border-secondary p-5">
        <div className="row">
          <div className="col-8 col-lg-2 ">
            <div className="d-flex flex-column">
              <img
                src={LogoImage}
                alt="Logo"
                className=" d-lg-block"
                height="30"
                style={{ width: "7.75rem" }}
              />
              <span className="text-white nowrap mt-2 mb-5">
                Your Best Crypto Partner
              </span>
            </div>
          </div>
          <div className="col-12 col-lg-4 mt-4 ">
            <div className="text-white">
              <p>DOTBLOX LABS (DTBX)</p>
              <a href="mailto:support@dotblox.io" target="_blank" referrerPolicy="no-referrer" className="text-primary">Support@dotblox.io</a>
              {/* <p>Building Name: Shanti Dwar CHS LTD, Shantivan,</p>
              <p>Near National Park, Borivali East</p>
              <p>Mumbai 400066 Maharashtra</p> */}
            </div>
          </div>
          <div className="col-6 col-lg-4 mt-4 ">
            <div className="row">
              <h6 className="fw-bold fs-5 text-white">Social Links</h6>
              <div className="col">
                <ul className="text-secondary">
                  <li className="mb-3">
                    <a
                      className="d-flex align-items-center"
                      href="https://twitter.com/Dotbloxblochain"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <AiFillTwitterCircle className="fs-4" />
                      <span className="align-bottom ms-2 fs-14">Twitter</span>
                    </a>
                  </li>
                  <li className="mb-3">
                    <a
                      className="d-flex align-items-center"
                      href="https://www.youtube.com/@DTBXBlockchain"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsYoutube className="fs-4" />
                      <span className="align-bottom ms-2 fs-14">YouTube</span>
                    </a>
                  </li>
                  <li className="mb-3">
                    <a
                      className="d-flex align-items-center"
                      href="https://www.facebook.com/profile.php?id=61552573607814"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsFacebook className="fs-4" />
                      <span className="align-bottom ms-2 fs-14">FaceBook</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="text-secondary">
                  <li className="mb-3">
                    <a
                      className="d-flex align-items-center"
                      href="https://www.instagram.com/dtbxblockchain/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsInstagram className="fs-4" />
                      <span className="align-bottom ms-2 fs-14">Instagram</span>
                    </a>
                  </li>
                  <li className="mb-3">
                    <a
                      className="d-flex align-items-center"
                      href="https://t.me/+TEXuq_NlgT8zYWE1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsTelegram className="fs-4" />
                      <span className="align-bottom ms-2 fs-14">Telegram</span>
                    </a>
                  </li>
                  <li className="mb-3">
                    <a
                      className="d-flex align-items-center"
                      href="https://www.linkedin.com/in/dotblox-blockchain-7a6ab7291/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsLinkedin className="fs-5" />
                      <span className="align-bottom ms-2 fs-14">Linkedin</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-2 mt-4 ">
            <div className="row">
              <h6 className="fw-bold fs-5 text-white">Legal Documents</h6>
              <div className="col">
                <ul className="text-secondary">
                  <li className="mb-3">
                    <a
                      className="d-flex align-items-center"
                      href={MSMECertificate}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="align-bottom ms-2 fs-14">- MSME Certificate</span>
                    </a>
                  </li>
                  <li className="mb-3">
                    <a
                      className="d-flex align-items-center"
                      href={UKCertificateofIncorporation}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="align-bottom ms-2 fs-14">- UK - Certificate of Incorporation</span>
                    </a>
                  </li>
                  <li className="mb-3">
                    <a
                      className="d-flex align-items-center"
                      href={CertificateofIncorporation}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="align-bottom ms-2 fs-14">- Certificate of Incorporation</span>
                    </a>
                  </li>
                  <li className="mb-3">
                    <a
                      className="d-flex align-items-center"
                      href={GSTCertificate}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="align-bottom ms-2 fs-14">- GST Certificate</span>
                    </a>
                  </li>
                  <li className="mb-3">
                    <a
                      className="d-flex align-items-center"
                      href={CentralKYCRegistrationCertificate}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="align-bottom ms-2 fs-14">- Central KYC Registration Certificate</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
