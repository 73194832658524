import { useDispatch, useSelector } from "react-redux";
import { user_logout } from "../redux/actions/authActions";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  CiMenuFries,
  CiViewBoard,
  CiMoneyCheck1,
  CiMoneyBill,
  CiLaptop,
} from "react-icons/ci";
import { IoNotificationsOutline } from "react-icons/io5";
import { getCoinRate, getRound } from "../redux/helpers/helper_functions";
import LogoImage from "../../public/main.png";
import { TbHistoryToggle } from "react-icons/tb";

export default function Header(props) {
  const dispatch = useDispatch();
  const { user, profile, isLoggedIn } = useSelector(
    (state) => state.AuthReducer
  );
  const { wallet, paired_curency_price, coins, wallet_loading } = useSelector(
    (state) => state.coinDBReducer
  );
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const [total_inr, setTotalInr] = useState(0);
  const [total_usdt, setTotalUsdt] = useState(0);

  async function backRouter() {
    dispatch(
      user_logout(() => {
        props.history?.replace("/login");
      })
    );
    document.location.reload();
  }

  useEffect(() => {
    if (!wallet_loading) {
      getWallets();
    }
  }, [wallet, coins, paired_curency_price]);

  function getWallets() {
    let total = 0;
    wallet &&
      Object.keys(wallet).map((res, i) => {
        let wallet_type = wallet[res]?.symbol.toUpperCase();
        let rate = getCoinRate(coins, wallet_type);
        let inr_val = rate * getRound(wallet[res]?.balance);
        total = total + parseFloat(inr_val);
      });
    setTotalInr(getRound(total));
    setTotalUsdt(getRound(total / paired_curency_price.USDT));
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-black mx-10 ">
        <Link className="navbar-brand" to="/">
          <img src={LogoImage} alt="Logo" className="" height="30" />
        </Link>
        {/* {isLoggedIn ? (
          <li className="nav-item d-none d-lg-block">
            <Link className="nav-link" to="/overview">
              <button className="btn btn-primary">Assets Overview</button>
            </Link>
          </li>
        ) : null} */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <CiMenuFries size={30} style={{ color: "white" }} />
        </button>
        <div
          className="offcanvas offcanvas-end bg-black"
          tabIndex="-1"
          id="navbarNav"
          aria-labelledby="navbarNavLabel"
        >
          <div className="offcanvas-header">
            <button
              type="button"
              className="btn-close bg-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body justify-content-lg-between bg-black ">
            <ul className="navbar-nav  w-100 justify-content-center">
              <li style={{ whiteSpace: "nowrap" }} className="nav-item">
                <Link className="nav-link text-white" to="/overview">
                  Assets
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" to="/Markets">
                  Market
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" to="/staking">
                  Staking
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" to="/staking-history">
                  Staking History
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" to="/AffiliateHome">
                  Affiliate
                </Link>
              </li>
              {isLoggedIn ? (
                <>
                  {/* <li className="nav-item dropdown">
                    <a
                      class="nav-link dropdown-toggle text-white"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Assets
                    </a>
                    <div
                      className="dropdown-menu bg-dark"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link
                        className="dropdown-item d-flex justify-content-start"
                        to="/exchange/trx-inr"
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <CiMoneyCheck1 size={25} className="text-white" />
                          <span className="text-white">Buy Crypto</span>
                        </div>
                      </Link>
                      <Link
                        className="dropdown-item d-flex justify-content-start"
                        to="/AssetsDeposit"
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <CiMoneyCheck1 size={25} className="text-white" />
                          <span className="text-white">Deposit Assests</span>
                        </div>
                      </Link>
                      <Link
                        className="dropdown-item d-flex justify-content-start"
                        to="/AssetsWithdraw"
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <CiMoneyCheck1 size={25} className="text-white" />
                          <span className="text-white">WithDraw Assets </span>
                        </div>
                      </Link>
                    </div>
                  </li> */}
                  <li className="nav-item">
                    <Link className="nav-link text-white" to="/SpotOrders">
                      Order
                    </Link>
                  </li>
                </>
              ) : null}
              <li className="nav-item">
                <Link className="nav-link text-white" to="/Contact">
                  Contact
                </Link>
              </li>

              {!isLoggedIn ? (
                <li className="nav-item">
                  <Link to="/login" className="nav-link text-white">
                    Sign In
                  </Link>
                </li>
              ) : null}
              {!isLoggedIn ? (
                <li className="nav-item">
                  <Link to="/create" className="nav-link text-white">
                    Get Started
                  </Link>
                </li>
              ) : null}
            </ul>

            <ul className="navbar-nav">
              {isLoggedIn ? (
                <li className="nav-item">
                  <a
                    className="nav-link "
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="circle_backdrop_outline border border-white">
                      <span className="text-white">
                        {" "}
                        {profile?.name
                          ? profile?.name
                            ?.split("")
                            .slice(0, 2)
                            .join("")
                            .toUpperCase()
                          : profile?.email
                            ?.split("")
                            .slice(0, 2)
                            .join("")
                            .toUpperCase()}
                      </span>
                    </div>
                  </a>
                  <div
                    className="dropdown-menu bg-dark"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link className="dropdown-item" to="/profile">
                      <div className="dropdown-body-item">
                        <span className="text-white">
                          {" "}
                          {profile?.email?.split("").slice(0, 2).join("") +
                            "***@***" +
                            profile?.email?.substr(profile?.email.indexOf("."))}
                        </span>
                      </div>
                    </Link>
                    <Link className="dropdown-item" to="/AccountSecurity">
                      <div className="dropdown-body-item">
                        <span className="text-white">Account Security</span>
                      </div>
                    </Link>
                    <Link className="dropdown-item" to="/AccountVerification">
                      <div className="dropdown-body-item">
                        <span className="text-white">
                          Identity Verification
                        </span>
                      </div>
                    </Link>
                    <div class="dropdown-divider"></div>
                    <div>
                      {" "}
                      <a
                        className="dropdown-item cursor_pointer border-top"
                        onClick={() => {
                          backRouter();
                        }}
                      >
                        <div className="dropdown-body-item text-center text-primary">
                          Log Out
                        </div>
                      </a>
                    </div>
                  </div>
                </li>
              ) : null}

              {isLoggedIn ? (
                <li className="nav-item">
                  <a
                    className="nav-link text-white"
                    data-bs-toggle="offcanvas"
                    href="#notification_toggle"
                    role="button"
                    aria-controls="notification_toggle"
                  >
                    <div className="circle_backdrop">
                      <i className="fa fa-bell-o fs-6"></i>
                    </div>
                  </a>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </nav>

      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="notification_toggle"
        aria-labelledby="notification_toggleLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="notification_toggleLabel">
            Notifications
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div>news1</div>
        </div>
      </div>
    </>
  );
}
