import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import Header from './HomeComp/Header';
import { N_completeOrder } from './redux/helpers/api_functions_new';
import { round } from './redux/helpers/Math';

function CompleteOrdersHistory(props) {
    const { user } = useSelector((state) => state.AuthReducer);
    const token = user?.params ? user.params.token : user.token;
    const [activeTab, setActiveTab] = useState("complete_order_history");
    const [completeOrderHistory, setCompleteOrderHistory] = useState(null)
    const [expandedRow, setExpandedRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // Change items per page as needed

    const options = {
        hour: "2-digit",
        minute: "2-digit",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    };

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        // You can add additional logic here if needed
    };

    useEffect(() => {
        N_completeOrder(token).then((res) => {
            if (res.status === 200) {
                setCompleteOrderHistory(res.params.trade_history.compleated)
            }
        })
    }, [])

    // Logic to get current items for the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = completeOrderHistory && completeOrderHistory.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    return (
        <>
            <Header {...props} />

            <div className='d-flex justify-content-center'>
                <div className="row">
                    <div className="activity_panel mb-4">
                        <div className="tab_width">
                            <div className="custom_pills">
                                <div className="row">
                                    <div className="nav nav-pills" id="nav-tab" role="tablist">
                                        <div
                                            className={`nav-item nav-link ${activeTab === "complete_order_history" ? "active" : ""
                                                } fs-16`}
                                            style={{ height: "35px", whiteSpace: "nowrap" }}
                                            onClick={() => handleTabClick("complete_order_history")}
                                        >
                                            Complete Order History
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tab-content">
                <div
                    className={`tab-pane fade ${activeTab === "complete_order_history" ? "show active" : ""
                        }`}
                    id="complete_order_history"
                >
                    <div className="overflow-auto mt-3 mx-5">
                        <div className="table_scroll_div">
                            {currentItems && currentItems.length > 0 ? (
                                <>
                                    <table className='table global_table table-bordered'>
                                        <thead>
                                            <tr className='text-center'>
                                                <th>No.</th>
                                                <th>Pair</th>
                                                <th>Amount</th>
                                                <th>Price</th>
                                                <th>Total</th>
                                                <th>Executed</th>
                                                <th>Completed</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentItems && currentItems.length > 0
                                                ? currentItems.map((item, key) => {
                                                    return (
                                                        <>
                                                            <tr onClick={() => setExpandedRow(expandedRow === key ? null : key)} style={{ cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Click to View More Trades">
                                                                <td className={` ${item.type === "buy" ? "text-green" : "text-red"} text-center`}>{key + 1 + (currentPage - 1) * itemsPerPage}</td>
                                                                <td className={` ${item.type === "buy" ? "text-green" : "text-red"} text-center`}>
                                                                    {item.currency_type?.toUpperCase()}
                                                                </td>
                                                                <td className={` ${item.type === "buy" ? "text-green" : "text-red"} text-center`}>
                                                                    {/* {item.StakingSource.toUpperCase()} */}
                                                                    {round(item.volume)}
                                                                </td>
                                                                <td className={` ${item.type === "buy" ? "text-green" : "text-red"} text-center`}>
                                                                    {/* {item.harvest} */}
                                                                    {round(item.raw_price)}
                                                                </td>
                                                                <td className={` ${item.type === "buy" ? "text-green" : "text-red"} text-center`}>
                                                                    {/* {item.invest_time} days */}
                                                                    {round(item?.volume * item?.raw_price)}
                                                                </td>
                                                                <td className={` ${item.type === "buy" ? "text-green" : "text-red"} text-center`}>
                                                                    {/* {item.staked_amount} */}
                                                                    {item.total_executed}
                                                                </td>
                                                                <td className={` ${item.type === "buy" ? "text-green" : "text-red"} text-center`}>
                                                                    {new Date(Number(item.timestamp)).toLocaleString()}
                                                                </td>
                                                                <td className={` ${item.type === "buy" ? "text-green" : "text-red"} text-center`}>
                                                                    {item?.status !== "c" ? (
                                                                        <span className="">Cancel</span>
                                                                    ) : (
                                                                        <span className="">Executed</span>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            {expandedRow === key && (
                                                                <tr>
                                                                    <td colSpan="9">
                                                                        <div className="accordion" id={`accordion${key}`}>
                                                                            <div className="accordion-item">
                                                                                <div id={`collapse${key}`} className="accordion-collapse collapse show" aria-labelledby={`heading${key}`} data-bs-parent={`#accordion${key}`}>
                                                                                    <div className="accordion-body bg-light">
                                                                                        <table className="table">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Trade Date</th>
                                                                                                    <th>Price</th>
                                                                                                    <th>Volume</th>
                                                                                                    <th>Transaction Fee</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {item.trades.map((trade, index) => (
                                                                                                    <tr key={index}>
                                                                                                        <td>{new Date(Number(trade.trade_date)).toLocaleString()}</td>
                                                                                                        <td>{trade.price}</td>
                                                                                                        <td>{trade.volume}</td>
                                                                                                        <td>{trade.transaction_fee}</td>
                                                                                                    </tr>
                                                                                                ))}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </>
                                                    );
                                                })
                                                : null}
                                        </tbody>
                                    </table>
                                    <div className='d-flex justify-content-center'>
                                        {completeOrderHistory && completeOrderHistory.length > 0 && (
                                            <ul className="pagination">
                                                {[...Array(Math.ceil(completeOrderHistory.length / itemsPerPage)).keys()].map((number) => (
                                                    <li key={number} className="page-item">
                                                        <button onClick={() => paginate(number + 1)} className="page-link">
                                                            {number + 1}
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </>
                            ) : <div className="container">
                                <div className="row justify-content-center mt-5">
                                    <div className="col-md-6 text-center">
                                        <h2>No Complete Order History Found</h2>
                                        <p className="text-muted">It seems there is no order history available at the moment.</p>
                                        <p className="text-muted">Please check back later or try again.</p>
                                        {/* Optionally, you can include a button or link to navigate back */}
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default CompleteOrdersHistory
