import DownloadAppImage from "../../public/Phone.png";
import GooglePlayIcon from "../../public/google.png";
import AppleStoreIcon from "../../public/apple.png";
import { Modal } from "react-bootstrap";
import { useState } from "react";


export default function DownloadApp() {
  const [popup, setpopup] = useState(false);
  const handleClose = () => setpopup(false);


  return (
    <>
      <Modal size="sm" centered show={popup} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100 h6">
            <span className="">Message</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">App is coming soon</p>
        </Modal.Body>
      </Modal>
      <div className="mt-5 padding-bottom">
        <div className="container">
          <div className="row">
            <div className="text-white col order-2 order-lg-1">
              <div className=" h-100 d-flex justify-content-center align-items-center">
                <img src={DownloadAppImage} alt="" />
              </div>
            </div>
            <div className="text-white  col-lg order-1 order-lg-1 d-flex flex-column align-items-lg-center justify-content-lg-center">
              <div className="container ">
                <h1 className="fw-bold display-4 mb-4 text-lg-left w-75">
                  Build your crypto portfolio anywhere.
                </h1>
                <p className=" text-lg-left fs-5 w-75">
                  A powerful cryptocurrency exhange in your pocket. Buy, sell and
                  trade on the go.
                </p>
              </div>
              <div className="container mt-5">
                <h6>Get the DTBX Mobile App Now!</h6>
                <div className="row">
                  <div className="col-md-6 ">
                    <div className="text-center mt-4 text-white app-btn rounded-5 px-5 border w-100 d-flex justify-content-center">
                      <a
                        className="btn text-white d-flex align-items-center"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                          e.preventDefault()
                          setpopup(true)
                        }}
                      >
                        <img
                          src={GooglePlayIcon} // Replace with the actual path to your Google Play store icon image
                          alt="Get it on Google Play"
                          className="google-play-icon me-2"
                          style={{ maxWidth: "24px", height: "auto" }}
                        />
                        <div className="d-flex flex-column justify-content-start">
                          <span
                            style={{ fontSize: "0.75rem" }}
                            className="text-left"
                          >
                            Get it on Google Play
                          </span>
                          <span className=" text-left fs-3">Google Play</span>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="text-center mt-4 text-white app-btn rounded-5 px-5 border w-100 d-flex justify-content-center">
                      <a
                        className="btn text-white d-flex align-items-center "
                        rel="noopener noreferrer"
                        onClick={(e) => {
                          e.preventDefault()
                          setpopup(true)
                        }}
                      >
                        <img
                          src={AppleStoreIcon} // Replace with the actual path to your Google Play store icon image
                          alt="Get it on Google Play"
                          className="google-play-icon me-2"
                          style={{ maxWidth: "24px", height: "auto" }}
                        />
                        <div className="d-flex flex-column justify-content-start">
                          <span
                            style={{ fontSize: "0.75rem" }}
                            className="text-left"
                          >
                            Download on the
                          </span>
                          <span className=" text-left fs-3">App Store</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}