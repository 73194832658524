import { Link } from "react-router-dom";

export default function Footer() {
    return (
      <footer className="bg-black text-light py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p>DTBX @ All rights reserved</p>
            </div>
            <div className="col-md-6 text-md-end">
              <p>
                <Link to="/terms_&_conditions">Terms of Service</Link> 
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }