import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { BUY_MARKET_PRICE, SELL_MARKET_PRICE, SET_ORDER_BOOK } from './redux/constant';
import { Tooltip, styled, tooltipClasses } from '@mui/material';
import { round } from "./redux/helpers/Math";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import Loader from "./components/Loader";
import { setPrice } from "./redux/actions/appActions";

const reverseArr = (input) => {
  return input
    .map((item) => ({
      ...item,
      raw_price: round(item.raw_price),
      volume: round(item.volume),
    }))
    .filter((item) => item.volume > 0)
    .reverse();
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
    "&::before": {
      backgroundColor: theme.palette.common.black,
      border: "1px solid #f5f5f5",
    },
  },

  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    maxWidth: 300,
  },
}));

const OrderTable = React.memo(({ data, type, handleClick }) => {
  const getPercentage = useCallback((cv, arr) => {
    const totalVolume = arr.reduce((acc, item) => acc + parseFloat(item.volume), 0);
    return (Number(cv) * 100) / Number(totalVolume);
  }, []);

  return (
    <table className="order-book-table exchange_tbl" style={{ height: 'fit-content' }}>
      <tbody className={type}>
        {data?.map((d, index) => (
          <HtmlTooltip
            key={index}
            title={
              <React.Fragment>
                <div className="tooltip_box">
                  <div className="d-flex justify-content-between mb-1">
                    <div>Price</div>
                    <div>{round(d.raw_price)}</div>
                  </div>
                  <div className="d-flex justify-content-between mb-1">
                    <div>Amount</div>
                    <div>{round(d.volume)}</div>
                  </div>
                  <div className="d-flex justify-content-between mb-1">
                    <div>Total</div>
                    <div>{round(d.volume) * round(d.raw_price)}</div>
                  </div>
                </div>
              </React.Fragment>
            }
            placement="left"
          >
            <tr onClick={() => handleClick(d.raw_price, d.volume, type)}>
              <td className={`text-start text-${type === 'sell' ? 'red' : 'green'}`}>
                {round(d.raw_price)}
              </td>
              <td className={`text-${type === 'sell' ? 'red' : 'green'}`}>{round(d.volume)}</td>
              <td className="text-end">{(d.volume * d.raw_price).toFixed(2)}</td>
              <div
                className="filler"
                style={{ width: `${getPercentage(d.volume, data)}%` }}
              ></div>
            </tr>
          </HtmlTooltip>
        ))}
      </tbody>
    </table>
  );
});

function OrderSystemTab({
  id,
  coinname,
  coins,
  paired_curency_price,
  buy_order_book,
  sell_order_book,
  order_book_loading,
  vhl_data
 }) {

  const [state, setState] = useState({
    activeTab: 0,
    sarr: [],
    price: 0,
    barr: [],
    isSell: '',
  });

  const dispatch = useDispatch();

  const setsarr = useCallback((arr) => {
    setState((prevState) => ({ ...prevState, sarr: arr }));
  }, []);

  const setprice = useCallback((price) => {
    setState((prevState) => ({ ...prevState, price }));
  }, []);

  const setbarr = useCallback((arr) => {
    setState((prevState) => ({ ...prevState, barr: arr }));
  }, []);

  const setIsSell = useCallback((sellStatus) => {
    setState((prevState) => ({ ...prevState, isSell: sellStatus }));
  }, []);


  const sortedBuyOrders = useMemo(() => {
    if (buy_order_book[coinname]) {
      return [...buy_order_book[coinname]].sort((a, b) => a.raw_price - b.raw_price);
    }
    return [];
  }, [buy_order_book, coinname]);

  const reversedBuyOrders = useMemo(() => reverseArr(sortedBuyOrders), [sortedBuyOrders]);

  const sortedSellOrders = useMemo(() => {
    if (sell_order_book[coinname]) {
      return [...sell_order_book[coinname]].sort((a, b) => a.raw_price - b.raw_price);
    }
    return [];
  }, [sell_order_book, coinname]);

  const reversedSellOrders = useMemo(() => reverseArr(sortedSellOrders), [sortedSellOrders]);

  useEffect(() => {
    if (buy_order_book || sell_order_book) {
      setbarr(reversedBuyOrders);
      setsarr(reversedSellOrders);
    }
  }, [buy_order_book, sell_order_book, reversedBuyOrders, reversedSellOrders, setbarr, setsarr]);

  useEffect(() => {
    dispatch({ type: SET_ORDER_BOOK, order_book_loading: false });
  }, [dispatch]);

  useEffect(() => {
    const matchingKey = vhl_data[id];

    if (matchingKey) {
      const matchingData = vhl_data[id];
      if (matchingData) {
        setIsSell(state.price >= matchingData.raw_price ? "up" : "down");
        setprice(matchingData.raw_price);
      }
    } else {
      const foundCoin = Object.values(coins).find(d => d.symbol === coinname.split("-")[0].toUpperCase());
      if (foundCoin) {
        const pairedPrice = paired_curency_price?.[coinname.split("-")[1].toUpperCase()] || 1;
        setprice(Number(foundCoin.current_price) / Number(pairedPrice));
      }
    }
  }, [vhl_data, id, coins, paired_curency_price, coinname, state.price]);

  const handleClick = useCallback((raw_price, volume, type) => {
    dispatch({
      type: type === 'sell' ? SELL_MARKET_PRICE : BUY_MARKET_PRICE,
      data: {
        marketprice: raw_price,
        marketvolume: volume,
        active: type === 'sell' ? 0 : 1,
      },
    });
  }, [dispatch]);

  const handlePriceSelection = useCallback((selectedPrice) => {
    dispatch(setPrice(selectedPrice));
  }, [dispatch]);

  return (
    <>
      <div className="card custom_scrollbar">
        <div className="card-header">Order Book</div>
        <div>
          <div className="">
            <div className="min_height416">
              {order_book_loading ? (
                <Loader />
              ) : buy_order_book.length !== 0 || sell_order_book.length !== 0 ? (
                <>
                  <table className="order-book-table exchange_tbl">
                    <thead className="sticky_thead">
                      <tr>
                        <th className="text-start">
                          <h6>Price</h6>
                        </th>
                        <th className="text-center">
                          <h6>Quantity</h6>
                        </th>
                        <th className="text-end">
                          <h6>Amount</h6>
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <div
                    className="buy_minheight custom_scrollbar table_on_hover"
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                  >
                    <div className=" sticky_thead"></div>
                    <OrderTable data={state.sarr} type="sell" handleClick={handleClick} />
                  </div>

                  <div className="buy_minheight custom_scrollbar table_on_hover">
                    <div className="table_center_div sticky_thead">
                      <div
                        className={`${state.isSell === 'up' ? 'text-green' : 'text-red'} fs-16`}
                        onClick={() => handlePriceSelection(round(state.price))}
                      >
                        {parseFloat(state.price)}
                        {state.isSell === 'up' ? (
                          <BsArrowUpShort className="mx-2" size={24} />
                        ) : (
                          <BsArrowDownShort className="mx-2" size={24} />
                        )}
                      </div>{' '}
                    </div>

                    <OrderTable data={state.barr} type="buy" handleClick={handleClick} />
                  </div>
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ flex: 1, height: '328px' }}
                >
                  <p className="mdfthemetxt">No record found</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderSystemTab