import React, { useEffect, useState } from "react";
import ProfileSidebar from "./components/ProfileSidebar";
import Header from "./HomeComp/Header";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import {
  N_checkKYCStatus,
  N_panVerify,
} from "./redux/helpers/api_functions_new";

function VerifyPan(props) {
  const { user, profile } = useSelector((state) => state.AuthReducer);
  const [pan_no, setPan_no] = useState(null);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [panDetails, setPanDetails] = useState(null);
  const [btnDisable, setBtnDisable] = useState(true)
  const token = user?.params ? user.params.token : user.token;

  const verifyPan = () => {
    if (pan_no) {
      try {
        N_panVerify(pan_no, token).then((res) => {
          if (res.status === 200) {
            NotificationManager.success("Pan Verification Successfull");
            setVerificationSuccess(true);
            setTimeout(() => {
              window.location.reload();
            }, 6000);
          } else if (res.status === 400) {
            NotificationManager.error(res.message)
          } else {
            NotificationManager.info("Something Went Wrong !! Try after some time")
          }
        });
      } catch (error) {
        NotificationManager.error(error.message);
      }
    } else {
      NotificationManager.error("Please Provide Pan Number");
    }
  };

  useEffect(() => {
    N_checkKYCStatus(token).then((d) => {
      if (d.status === 200) {
        if (d.params.status === 1 && d.params.doc_1_no) {
          setBtnDisable(false)
        }
        if (d.params.status === 1 && d.params.doc_2_no) {
          setPanDetails(d.params);
          setVerificationSuccess(true)
        }
      }
    });
  }, []);

  return (
    <>
      <Header {...props} />

      <div className="s-layout">
        <div className="s-layout__sidebar">
          <ProfileSidebar />
        </div>
      </div>

      <main className="s-layout__content">
        <div className="dashboard_header">
          <h3 className="header_title">Pan Card Verification</h3>
        </div>
        <div className="container-fluid">
          <div className="account_page">
            <div className="row">
              <div className="col-lg-12 mb-5">
                {verificationSuccess && panDetails ? (
                  <span className="mt-3">
                    <button className="btn btn-success btn-lg rounded-pill">
                      Verified
                    </button>
                    <div className="mt-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="row mt-2 mb-2">
                            <h6 className="col-md-12 page-title">
                              Your Pan Card Details
                            </h6>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <table className="table table-bordered">
                                <thead className="bg-light">
                                  <tr>
                                    <th className="tdCenter">Name</th>
                                    <th className="tdCenter">
                                      Pan Card Number
                                    </th>
                                    <th>Date of Birth</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="tdCenter">
                                      {panDetails.name}
                                    </td>
                                    <td className="tdCenter">
                                      {panDetails.doc_2_no}
                                    </td>
                                    <td className="tdCenter">
                                      {new Date(Number(panDetails.doc_1_dob)).toLocaleDateString()}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                ) : null}
                {!verificationSuccess && (
                  <div>
                    <h4 className="h5 fw-bold mb-0">
                      Verify your identity for enhanced account security and
                      expanded service access.
                    </h4>
                    <div className="card mt-3">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-8">
                            <div className="form-group">
                              <label className="text-muted" htmlFor="pan">
                                Pan Number
                              </label>
                              <input
                                type="text"
                                className="form-control buy-sell-form-bg buy-sell-theme text-black"
                                id="pan"
                                name="pan"
                                onChange={(e) => {
                                  e.preventDefault();
                                  setPan_no(e.target.value);
                                }}
                              />
                              {btnDisable && <p className="text-danger">Please Verify Your Aadhar First</p>}
                              <button
                                onClick={verifyPan}
                                className="btn btn-primary mt-2"
                                disabled={btnDisable}
                              >
                                Verify Pan Number
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default VerifyPan;
