import React, { useEffect, useState } from "react";
import "./Index.css";

import Header from "./HomeComp/Header";
import Hero from "./HomeComp/HeroSection";
import Features from "./HomeComp/Features";
import Usage from "./HomeComp/Usage";
import WhyChooseUS from "./HomeComp/WhyChooseUs";
import MediaReview from "./HomeComp/MediaReview";
import DownloadApp from "./HomeComp/DownloadApp";
import Newsletter from "./HomeComp/Newsletter";
import FooterNav from "./HomeComp/FooterNav";
import Footer from "./HomeComp/Footer";
import { Button, Modal } from "react-bootstrap";
import FinanceImg from '../public/IMG_0230.JPG'
import Blog from "./HomeComp/Blog";
import { useSelector } from "react-redux";
import { adminurl } from "./redux/helpers/api_functions_new";
// import Carousel from "./HomeComp/Carousel";

function Index(props) {
  const [popup, setpopup] = useState(true);
  const handleClose = () => setpopup(false);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const bannerImg = webData?.banner_url
  const bannerStatus = webData?.banner_status

  // useEffect(() => {
  //   var Tawk_API = Tawk_API || {},
  //     Tawk_LoadStart = new Date();
  //   (function () {
  //     var s1 = document.createElement("script"),
  //       s0 = document.getElementsByTagName("script")[0];
  //     s1.async = true;
  //     s1.src = "https://embed.tawk.to/61826b076bb0760a4940ed61/1fjin45kt";
  //     s1.charset = "UTF-8";
  //     s1.setAttribute("crossorigin", "*");
  //     s0.parentNode.insertBefore(s1, s0);
  //   })();
  // }, []);

  return (
    <div className="bg-black line-bg-img">
      <div className="">
        <Header />
        <div className="mx-10">
          <Hero />
          {/* <Carousel /> */}
          <Features />
          <Usage />
          <WhyChooseUS />
          {/* <MediaReview /> */}
          <DownloadApp />
          <Newsletter />
          <Blog />
        </div>
        <FooterNav />
        <Footer />
        {bannerStatus && (
          <Modal show={popup} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Modal.Body>
              <img src={`${adminurl}${bannerImg}`} alt="Banner" />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>Close</Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default Index;
