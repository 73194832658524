import React, { useEffect, useState } from "react";
import Router from "./pages/router";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./pages/Globalstyle";
import { darkTheme, lightTheme } from "./pages/Themes";
import { useDispatch, useSelector } from "react-redux";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import AutoLogout from "./pages/components/Autologout";
import { user_logout } from "./pages/redux/actions/authActions";



function App(props) {
  const { switch_theme, isLoggedIn } = useSelector((state) => state.AuthReducer);
  const dispatch = useDispatch();

  function backRouter() {
    dispatch(
      user_logout(() => {
        props.history?.replace("/login");
      })
    );
    document.location.reload();
  }

  const handleLogout = () => {
    // Your logout logic here
    backRouter()
    console.log('User has been logged out due to inactivity');
    // For example, clear the auth token, update the app state, redirect to login page, etc.
  };

  return (
    <ThemeProvider theme={switch_theme === "light" ? lightTheme : darkTheme}>
      <>
        {/* <GlobalStyles /> */}
        <Router {...props} />
        <NotificationContainer />
        <FloatingWhatsApp phoneNumber="7208498652" accountName="DTBX EXCHANGE" />
        {/* {isLoggedIn && (
          <AutoLogout onLogout={handleLogout} isLoggedIn={isLoggedIn} />
        )} */}
      </>
    </ThemeProvider>
  );
}

export default App;
