import React from 'react';
import CryptoBlogImage from '../../public/crypto-blog-re.png';

export default function Blog() {
    return (
        <div className="container-fluid mt-5 blog_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg order-1 order-lg-1 d-flex flex-column align-items-lg-center justify-content-lg-center">
                        <div className="container">
                            <h3 className="text-center fs-4 mb-3">
                                Discover the fundamentals of cryptocurrency if you're just starting out.
                            </h3>
                            <h2 className="display-4 fw-bold text-center">Learning is Earning</h2>
                            <div className="row mt-5 justify-content-center">
                                <div className="col-lg-4">
                                    <div className="card bg-dark rounded-2">
                                        <img src={CryptoBlogImage} className="card-img-top" alt="Crypto Blog" />
                                        <div className="card-body">
                                            <h5 className="card-title">Unlocking the Power of Crypto</h5>
                                            <p className="card-text">Discover informative articles, expert opinions, and in-depth analyses to enhance your understanding of cryptocurrency..</p>
                                            <a href="https://dotblox.io/blog-list" target="_blank" rel="noopener noreferrer" className="btn btn-primary">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
