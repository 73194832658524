import React, { useEffect, useState } from "react";
import Header from "./HomeComp/Header";
import ProfileSidebar from "./components/ProfileSidebar";
import { BiError, BiMobileAlt, BiSolidCheckShield } from "react-icons/bi";
import { AiOutlineCamera, AiOutlineCloudUpload } from "react-icons/ai";
import {
  N_addBinficiary,
  N_checkKYCStatus,
  N_getBank,
  N_getCountry,
  N_getUserBankDetails,
  N_profileData,
  N_updateUPI,
  N_uploadBankDoc,
  N_uploadID,
} from "./redux/helpers/api_functions_new";
import { useSelector } from "react-redux";
import $ from "jquery";
import { NotificationManager } from "react-notifications";
import Loader from "./components/Loader";
// import Footer from "./HomeComp/Footer";
export default function MyBanks(props) {
  const { user, profile } = useSelector((state) => state.AuthReducer);
  const [loading, setLoading] = React.useState(true);
  const [isverified, setIsverified] = useState(0);
  const [filedata, setFileData] = useState();

  const [accountNo, setAccountNo] = React.useState();
  const [UPI_ID, setUPI_ID] = React.useState();
  const [reaccountNo, setReAccountNo] = React.useState();
  const [account_name, setAccountName] = React.useState();
  const [selectedBank, setSelectedBank] = React.useState();
  const [bankIFCS, setIFSC] = React.useState([]);
  const [banklist, setBank] = React.useState();
  const [accountType, setAccountType] = React.useState();
  const [bankStatus, setBankStatus] = React.useState(-1);
  const [bankDetails, setBankDetails] = React.useState({});
  const [isFileUploaded, setFileUploaded] = React.useState(false);
  const [IsUpdateUPI, setIsUpdatedUPI] = useState(false);
  const [updatedUPI, setUpdatedUPI] = useState('')
  const token = user?.params ? user.params.token : user.token;



  const editBankDetails = (e) => {
    e.preventDefault();
    const token = user?.params ? user.params.token : user.token;
    N_getUserBankDetails(token).then((d) => {
      if (d.status === 200) {
        const { account_number, account_type, bank_name, doc, name, ifsc } =
          d.params.bank_details;
        setAccountNo(account_number);
        setReAccountNo(account_number);
        setAccountName(name);
        setSelectedBank(bank_name);
        setIFSC(ifsc);
      }
    });
  };

  useEffect(() => {
    N_getBank(user?.params ? user.params.token : user.token)
      .then((d) => {
        if (d.status === 200) {
          setBank(d.params.bank);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, [user]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!accountNo) {
      NotificationManager.error("Please Provide Account Number");
    } else {
      if (accountNo == "" && bankIFCS == "") {
        NotificationManager.error("Fill all field!");
      } else {
        // console.log("OK");
        setLoading(true);
        N_addBinficiary(
          accountNo,
          UPI_ID,
          // reaccountNo,
          selectedBank,
          // account_name,
          bankIFCS,
          accountType,
          user?.params ? user.params.token : user.token
        ).then((d) => {
          if (d.status == 200) {
            // N_uploadBankDoc(
            //   e,
            //   filedata,
            //   "doc",
            //   user?.params ? user.params.token : user.token
            // );
            NotificationManager.info(d.message);
            setTimeout(() => {
              window.location.reload();
            }, 6000);
          } else {
            NotificationManager.error(d.message);
          }
          setLoading(false);
        });
      }
    }
  };

  useEffect(() => {
    N_getUserBankDetails(user?.params ? user.params.token : user.token)
      .then((data) => {
        if (data.status === 200) {
          setBankStatus(data.params.bank_details.status);
          if (data.params.bank_details.status == 1)
            setBankDetails(data.params.bank_details);
        } else if (data.status === 400) {
          setBankStatus(data.params.bank_status);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, [user]);

  function readURL(input) {
    const file = input.target.files[0];
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      setFileData(file);
      setFileUploaded(true);
      reader.onload = function (e) {
        $("#check_photo").attr("src", e.target.result);
      };
    }
  }

  return (
    <>
      <Header {...props} />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <ProfileSidebar />
        </div>

        <main className="s-layout__content">
          <div className="dashboard_header">
            <h3 className="header_title">Bank Verification</h3>
          </div>
          <div className="container-fluid">
            <div className="account_page">
              <div class="row">
                <div class="col-lg-8">
                  <div className="col-lg-12 mb-5">
                    {/* <div className="text-lgray">
                      The verification only takes 1-2 minutes.
                    </div> */}
                    {/* <p className="">
                      <span className="fs-4"> 🎉 </span> Identity verification
                      passed. Enjoy enhanced account protection and services
                      now!
                    </p> */}

                    {!loading && bankStatus == -1 ? (
                      <span className="mt-3">
                        <button className="btn btn-info btn-lg rounded-pill">
                          Pending
                        </button>
                        <div className="h5 p-2">
                          Your Bank details under Review for 72 Hours .{" "}
                        </div>
                      </span>
                    ) : null}

                    {!loading && bankStatus == 1 ? (
                      <span className="mt-3">
                        <button className="btn btn-success btn-lg rounded-pill">
                          Verified
                        </button>
                        <div className="mt-3">
                          <div className="card">
                            <div className="card-body">
                              <div className="row mt-2 mb-2">
                                <h6 className="col-md-12 page-title">
                                  Your bank account details for IMPS payments
                                </h6>
                              </div>

                              <div className="row">
                                <div className="col-md-12">
                                  <table className="table table-bordered">
                                    <thead className="bg-light">
                                      <tr>
                                        <th className="tdCenter">Name</th>
                                        <th className="tdCenter">
                                          Account Number
                                        </th>
                                        <th className="tdCenter">IFSC Code</th>
                                        <th className="tdCenter">
                                          Account Type
                                        </th>
                                        <th className="tdCenter">Bank Name</th>
                                        <th className="tdCenter">UPI ID</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="tdCenter">
                                          {bankDetails.name}
                                        </td>
                                        <td className="tdCenter">
                                          {bankDetails.account_number}
                                        </td>
                                        <td className="tdCenter">
                                          {" "}
                                          {bankDetails.ifsc}{" "}
                                        </td>
                                        <td className="tdCenter">
                                          {" "}
                                          {bankDetails.account_type}{" "}
                                        </td>
                                        <td className="tdCenter">
                                          {" "}
                                          {bankDetails.bank_name}{" "}
                                        </td>
                                        <td className="tdCenter">
                                          {bankDetails.upi_id ? (bankDetails.upi_id) : (
                                            <form>
                                              <input
                                                className="form-control text-white"
                                                type="text"
                                                disabled={!IsUpdateUPI}
                                                value={updatedUPI}
                                                onChange={(e) => setUpdatedUPI(e.target.value)}
                                              />
                                              {IsUpdateUPI ? (<button className="btn btn-success" onClick={(e) => {
                                                e.preventDefault()
                                                if (updatedUPI && updatedUPI.length > 5) {
                                                  N_updateUPI(updatedUPI, token).then((res) => {
                                                    if (res.status === 200) {
                                                      NotificationManager.success(res.message)
                                                      setTimeout(() => {
                                                        window.location.reload();
                                                      }, 3000);
                                                    } else {
                                                      NotificationManager.error(res.message)
                                                    }
                                                  }).catch((err) => {
                                                    NotificationManager.success(err.message)
                                                  })
                                                } else {
                                                  NotificationManager.info("Please Provide Valid UPI id")
                                                }
                                              }}>DONE</button>) : (<button className="btn btn-primary" onClick={(e) => {
                                                e.preventDefault()
                                                setIsUpdatedUPI(true)
                                              }}>Update</button>)}
                                            </form>
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </span>
                    ) : null}
                    {loading ? <Loader /> : null}
                    {!loading && (bankStatus == 0 || bankStatus == 2) ? (
                      <>
                        <h4 className="h5 fw-bold mb-0">
                          Verify your identity for enhanced account security and
                          expanded service access.
                        </h4>
                        <div className="card-body">
                          <div className="row">
                            <form
                              className="signupform mdfthemetxt"
                              method="post"
                              id="loginForm"
                              autoComplete="off"
                              onSubmit={onSubmit}
                            >
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form-group mb-3">
                                    <label
                                      for="exampleInputEmail1"
                                      className="text-muted"
                                    >
                                      Account Number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control colr"
                                      placeholder="Enter Account no"
                                      value={accountNo}
                                      onChange={(e) =>
                                        setAccountNo(
                                          e.target.value
                                            .replace(/[^0-9.]/g, "")
                                            .replace(/(\..*?)\..*/g, "$1")
                                        )
                                      }
                                    />
                                  </div>

                                  {/* <div className="form-group mb-3">
                                    <label
                                      for="exampleInputEmail1"
                                      className="text-muted"
                                    >
                                      Account Holder Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control buy-sell-form-bg buy-sell-theme"
                                      placeholder="Enter Account Name"
                                      value={account_name}
                                      onChange={(e) =>
                                        setAccountName(e.target.value)
                                      }
                                    />
                                  </div> */}

                                  {/* <div className="form-group mb-3">
                                    <label
                                      for="exampleInputEmail1"
                                      className="text-muted"
                                    >
                                      Please attach the front page of your
                                      passbook or bank statement
                                    </label>
                                    <input
                                      type="file"
                                      className="form-control px-4 buy-sell-form-bg buy-sell-theme"
                                      placeholder="Enter Account Name"
                                      onChange={(e) => readURL(e)}
                                    />
                                  </div> */}
                                  <div className="form-group mb-3">
                                    <label
                                      for="exampleInputEmail1"
                                      className="text-muted"
                                    >
                                      Bank Name
                                    </label>
                                    <select
                                      className="form-control buy-sell-form-bg buy-sell-theme"
                                      onChange={(e) =>
                                        setSelectedBank(e.target.value)
                                      }
                                    >
                                      <option selected>Please Select</option>
                                      {banklist?.map((d) => (
                                        <option className="">
                                          {d.bank_name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="form-group mb-3">
                                    <label
                                      for="exampleInputEmail1"
                                      className="text-muted"
                                    >
                                      UPI ID
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control colr"
                                      placeholder="Enter UPI ID"
                                      value={UPI_ID}
                                      onChange={(e) => {
                                        setUPI_ID(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group mb-3">
                                    <label
                                      for="exampleInputEmail1"
                                      className="text-muted"
                                    >
                                      Account Type
                                    </label>
                                    <select
                                      className="form-control buy-sell-form-bg buy-sell-theme"
                                      onChange={(e) =>
                                        setAccountType(e.target.value)
                                      }
                                    >
                                      <option selected className="">
                                        Please Select
                                      </option>
                                      <option>Current</option>
                                      <option>Saving</option>
                                    </select>
                                  </div>

                                  <div className="form-group">
                                    <label
                                      for="exampleInputEmail1"
                                      className="text-muted"
                                    >
                                      Bank IFSC
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control colr"
                                      placeholder="Enter Bank IFSC"
                                      value={bankIFCS}
                                      onChange={(e) => setIFSC(e.target.value)}
                                    />
                                  </div>
                                  <div className="text-end">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      {loading ? (
                                        <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                                      ) : null}
                                      <span id="reg">SUBMIT</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main >
      </div >
    </>
  );
}
