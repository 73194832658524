import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import "./sidebar.css";
import { N_getLeaser } from "./redux/helpers/api_functions_new";
import { getCoinRate } from "./redux/helpers/helper_functions";
import { add, sub } from "./redux/helpers/Math";
import MyPNL from "./MyPNL";

export default function MyBonus() {
  const { user, isLoggedIn } = useSelector(
    (state) => state.AuthReducer
  );
  const {coins} = useSelector((state)=>state.coinDBReducer);
  const [action, setAction] = useState("leaser");
  const [referralKey, setReferralKey] = useState(null);
  const [rewardcoin, setRewardCoin] = useState("");
  const [rewardfee, setrewardfee] = useState(0);
  const [rewarddata, setRewardData] = useState(null);
  const [rewardUserEarning, setrewardUserEarning] = useState(null);
  const [referralUserData, setReferralUserData] = useState(null);
  const [referralUserEarning, setReferralUserEarning] = useState(null);
  const [levelIncome, setLevelIncome] = useState(null);
  const [levelUserEarning, setLevelUserEarning] = useState(null);
  const [levelTodayUserEarning, setLevelUserTodayEarning] = useState(null);
  const [total_deposit, setTotalDeposit] = useState(null);
  const [total_withdraw, setTotalWithdraw] = useState(null);
  const [total, setTotal] = useState(null);
  const [available_balanace, setAvailable] = useState(null);

  useEffect(() => {
    if (isLoggedIn) {
    if(action=="leaser") {
      N_getLeaser(user?.params ? user.params.token : user.token)
      .then((res)=>{
        if(res.status==200) {
          let deposit_data = 0;
          let withdraw_data = 0;
          let total_spot = 0;

          res?.deposit_data?.length>0 && res?.deposit_data?.map((item)=>{
            if(item?._id=="INR") {
              deposit_data += parseFloat(item?.total)
            } else {
              let rate = getCoinRate(coins, item?._id);
              deposit_data +=  (rate*parseFloat(item?.total));
            }
          })
          res?.deposit_inrdata?.length>0 && res?.deposit_inrdata?.map((item)=>{
            if(item?._id=="INR") {
              deposit_data += parseFloat(item?.total)
            } else {
              let rate = getCoinRate(coins, item?._id);
              deposit_data +=  (rate*parseFloat(item?.total));
            }
          })
          res?.withdraw_data?.length>0 && res?.withdraw_data?.map((item)=>{
            if(item?._id=="INR") {
              withdraw_data += parseFloat(item?.total)
            } else {
              let rate = getCoinRate(coins, item?._id);
              withdraw_data +=  (rate*parseFloat(item?.total));
            }
          })
          res?.withdraw_inrdata?.length>0 && res?.withdraw_inrdata?.map((item)=>{
            if(item?._id=="INR") {
              withdraw_data += parseFloat(item?.total)
            } else {
              let rate = getCoinRate(coins, item?._id);
              withdraw_data +=  (rate*parseFloat(item?.total));
            }
          })
         
          res?.wallet_data?.length>0 && res?.wallet_data?.map((item)=>{
            if(item?._id=="INR") {
              total_spot += parseFloat(item?.total)
            } else {
              let rate = getCoinRate(coins, item?._id);
              total_spot +=  (rate*parseFloat(item?.total));
            }
          })
          res?.stak?.length>0 && res?.stak?.map((item)=>{
            if(item?._id=="INR") {
              total_spot += parseFloat(item?.total)
            } else {
              let rate = getCoinRate(coins, item?._id);
              total_spot +=  (rate*parseFloat(item?.total));
            }
          })
          setTotalDeposit(deposit_data);
          setTotalWithdraw(withdraw_data);
          setAvailable(total_spot);
          let total_available = add(total_spot,withdraw_data);
          setTotal(sub(total_available,deposit_data));
        }
      })
    }
    
    
    }
  }, [isLoggedIn, action, coins]);
  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">

        <div className="container">
          <div className="marginTop">
            <div className="account_page">
                <div className="row">
                  <div className="activity_panel mb-4">
                    <div className="">
                      <div class="custom_pills">
                        <div class="nav nav-pills" id="nav-tab" role="tablist">
                          {/* <div
                            class="nav-item nav-link active fs-14"
                            style={{ height: "35px" }}
                            href="#signup"
                            data-toggle="tab"
                            onClick={() => {
                              setAction("signup");
                            }}
                          >
                            Signup Reward
                          </div>

                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#referral"
                            data-toggle="tab"
                            onClick={() => {
                              setAction("referral");
                            }}
                          >
                            Referral reward
                          </div>
                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#level"
                            data-toggle="tab"
                            onClick={() => {
                              setAction("level");
                            }}
                          >
                            Level reward
                          </div> */}
                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                             href="#leaser"
                            data-toggle="tab"
                            onClick={() => {
                              setAction("leaser");
                            }}
                          >
                            PNL
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-content">
                  {/* <div className="tab-pane fade active show" id="signup">

                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Symbol</th>
                              <th>Amount</th>
                              <th>Time</th>
                              <th>status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {rewarddata && rewarddata.length > 0 ? (
                              rewarddata.map((item) => {
                                return (
                                  <tr>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                        {item?.wallet_type}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                    <p>
                                        {item?.commission}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                        {new Date(
                                          item.createdAt
                                        ).toLocaleString()}
                                      </p>
                                    </td>
                                    <td className={`border-bottom-0 text-muted${item?.status?" text-green":" text-red"}`}>
                                      <p>
                                        {item?.status?"Fund release":"Pending"}
                                      </p>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan={9}
                                  className="text-center border-bottom-0 text-muted"
                                >
                                  <img
                                    src="/img/no-data.png"
                                    className="no-data-found"
                                  />
                                  <p>No records</p>
                                </td>
                              </tr>
                            )}

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="tab-pane fade" id="referral">
                   
                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Email</th>
                              <th>Name</th>
                              <th>Symbol</th>
                              <th>Amount</th>
                              <th>Kyc status</th>
                              <th>status</th>
                            </tr>
                          </thead>

                          <tbody>

                            {referralUserData && referralUserData.length > 0 ? (
                              referralUserData.map((item) => {
                                  return (
                                    <tr>
                                      <td className="border-bottom-0 text-muted">
                                      <p>
                                        {item?.email}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                      {item?.name?item?.name:"N/A"}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                      {item?.wallet_type?item?.wallet_type:0}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                      {item?.commission?item?.commission:0}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                        {item?.is_kyc_verified?"Done":"Pending"}
                                      </p>
                                    </td>
                                    <td className={`border-bottom-0 text-muted${item?.status?" text-green":" text-red"}`}>
                                      <p>
                                        {item?.status?"Fund release":"Pending"}
                                      </p>
                                    </td>
                                  </tr>
                                  );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan={9}
                                  className="text-center border-bottom-0 text-muted"
                                >
                                  <img
                                    src="/img/no-data.png"
                                    className="no-data-found"
                                  />
                                  <p>No records</p>
                                </td>
                              </tr>
                            )}

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="tab-pane fade" id="level">
                   
                   <div className="overflow-auto mt-3">
                     <div className="table_scroll_div">
                       <table className="table global_table">
                         <thead>
                           <tr>
                             <th>From</th>
                             <th>Symbol</th>
                             <th>Amount</th>
                             <th>Level</th>
                           </tr>
                         </thead>

                         <tbody>

                           {levelIncome && levelIncome.length > 0 ? (
                             levelIncome.map((item) => {
                                 return (
                                   <tr>
                                     <td className="border-bottom-0 text-muted">
                                     <p>
                                       {item?._from?.email}
                                     </p>
                                   </td>
                                   <td className="border-bottom-0 text-muted">
                                     <p>
                                     {item?.wallet_type?item?.wallet_type:0}
                                     </p>
                                   </td>
                                   <td className="border-bottom-0 text-muted">
                                     <p>
                                     {item?.commission?item?.commission:0}
                                     </p>
                                   </td>
                                   <td className="border-bottom-0 text-muted">
                                     <p>
                                       {item?.level}
                                     </p>
                                   </td>
                                 </tr>
                                 );
                             })
                           ) : (
                             <tr>
                               <td
                                 colSpan={9}
                                 className="text-center border-bottom-0 text-muted"
                               >
                                 <img
                                   src="/img/no-data.png"
                                   className="no-data-found"
                                 />
                                 <p>No records</p>
                               </td>
                             </tr>
                           )}

                         </tbody>
                       </table>
                     </div>
                   </div>
                 </div> */}
                 <div className="tab-pane fade active show" id="leaser">
                   
                <MyPNL totalDeposit={total_deposit}
              totalWithdraw={total_withdraw}
              availableBalance={available_balanace}
              total={total}
              />



                 </div>

                </div>
              
            </div>
          </div>
          </div>
        </main>
      </div>
    </>
  );
}
