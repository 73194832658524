import React, { useState, useEffect } from "react";
import "./exside.css";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getRound,
  sortByChange,
  sortByPair,
  sortByVol,
} from "../redux/helpers/helper_functions";
import { N_RemoteStatusAll } from "../redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
// import { SET_PAIRED_CURRENCY_PRICE } from "../redux/constant";
import { BiBitcoin } from "react-icons/bi";

export default function ExSide(props) {
  let {
    coins,
    user_fav_pairing,
    paired_curency_price,
    paired_curency,
    campare_currency,
  } = useSelector((state) => state.coinDBReducer);
  const coin = props.match.params.id.split("-");
  const [active, setActive] = useState(coin[1] ? coin[1] : campare_currency);
  const [data, setData] = useState([]);
  const [change, setChange] = useState(true);
  const [searchTxt, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [remoteCoins, setRemoteCoins] = useState([]);
  let coins_data = Object.values(coins);
  useEffect(() => {
    // N_getSupportedCurrency(user?.params ? user.params.user_id : user.user_id)
    //   .then((data) => {
    //     if (!data.error && data) {
    //       // console.log("prdc: ", data);
    //       pairedCur(data);
    //     } else {
    //       console.log(data);
    //       NotificationManager.error(data.message);
    //     }
    //   })
    //   .catch((e) => {
    //     console.log("error in getcrnc: ", e);
    //   });
    N_RemoteStatusAll()
      .then((data) => {
        if (data.status == 200) {
          setRemoteCoins(data.remoteTradingData);
        }
      })
      .catch((e) => {
        console.log("error in N_RemoteStatusAll: ", e);
      });
  }, []);

  useEffect(() => {
    let dd = coins_data.filter((d) => {
      if (d.symbol !== active.toUpperCase()) return d;
    });
    setData(dd);
  }, [coins_data.length, coins]);

  useEffect(() => {
    let dd;
    if (searchTxt != "") {
      dd = Object.values(coins_data).filter((d) => {
        if (d.symbol.startsWith(searchTxt?.toUpperCase())) return d;
      });
    } else {
      dd = Object.values(coins_data).filter((d) => {
        if (d.symbol !== active.toUpperCase()) return d;
      });
    }
    setData(dd);
  }, [searchTxt, active]);

  // useEffect(() => {
  //   let ddt = {};
  //   coins_data?.map((d, i) => {
  //     for (let i = 0; i < paired_cur.length; i++) {
  //       if (d.symbol === paired_cur[i].currency_coin) {
  //         ddt[d.symbol] = d.current_price_inr;
  //       }
  //     }
  //   });
  //   dispatch({ type: SET_PAIRED_CURRENCY_PRICE, data: ddt });
  //   setpc_price(ddt);
  // }, [paired_cur]);

  return (
    <div className="pt-1 eth-market">
      <div className="m-1">
        <div className="input-group exchange_input_grp--">
          <span className="input-group-text">
            <div className="">
              <i className="fa fa-search text-gray"></i>
            </div>
          </span>
          <input
            type="text"
            className="form-control text-start mp-exide-form bg-white"
            aria-label="Text input with checkbox"
            placeholder="Search "
            value={searchTxt}
            onChange={(e) => setSearch(e.target.value.toUpperCase())}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between  bg-gray-light">
        <div className="sidebar-overflow custom_scrollbar bg-white left_col">
          <div
            class="nav nav flex-column nav-pills nav-pills-custom "
            id="myTab"
            role="tablist"
          >
            {/* <button
              className={`nav-link ${active == "fav" ? "active" : ""} `}
              id="pln_currency"
              onClick={() => setActive("fav")}
              name="pln_currency"
            >
              <h6>
                <i className="fa fa-star text-warning"></i> Pair
              </h6>
            </button> */}
            {paired_curency && paired_curency.length > 0
              ? paired_curency.map((item, i) => (
                  <button
                    key={i} // You should also add a unique key when mapping over an array
                    id={i * 2 + 5}
                    className={` nav-link text-primary ${
                      active === item.currency_coin.toLowerCase()
                        ? "active"
                        : ""
                    } `}
                    onClick={() => setActive(item.currency_coin.toLowerCase())}
                    name="pln_currency"
                  >
                    {item.currency_coin}
                  </button>
                ))
              : null}
          </div>
        </div>

        <div className="bg-dark sidebar-overflow custom_scrollbar w-100" id="style-3">
          <table
            id="coins_table"
            className="table custom_tbl table-layout-- tablesorter"
            //style={{ height: "auto" }}
          >
            <thead>
              <tr className="border-top-0">
                <th
                  onClick={() => {
                    if (sort == "pair-asc") {
                      sortByPair(data, true, (result) => {
                        setData(result);
                        setChange(!change);
                        setSort("pair-dsc");
                      });
                    } else {
                      sortByPair(data, false, (result) => {
                        setData(result);
                        setChange(!change);
                        setSort("pair-asc");
                      });
                    }
                  }}
                >
                  <h6>
                    {" "}
                    Pairs
                    {sort == "pair-asc" ? (
                      <i className="fa fa-angle-up" aria-hidden="true"></i>
                    ) : null}
                    {sort == "pair-dsc" ? (
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    ) : null}
                  </h6>
                </th>

                <th
                  className=""
                  //   style={{ cursor: "pointer", outline: "none" }}
                  onClick={() => {
                    if (sort == "change-asc") {
                      sortByChange(data, true, (result) => {
                        setData(result);
                        setChange(!change);
                        setSort("change-dsc");
                      });
                    } else {
                      sortByChange(data, false, (result) => {
                        setData(result);
                        setChange(!change);
                        setSort("change-asc");
                      });
                    }
                  }}
                >
                  <h6>
                    Change
                    {sort == "change-asc" ? (
                      <i
                        className="fa fa-arrow-up"
                        aria-hidden="true"
                        style={{ fontSize: "10px" }}
                      ></i>
                    ) : null}
                    {sort == "change-dsc" ? (
                      <i className="fa fa-arrow-down" aria-hidden="true"></i>
                    ) : null}
                  </h6>
                </th>

                <th
                  className="text-end"
                  onClick={() => {
                    if (sort == "vol-asc") {
                      sortByVol(data, true, (result) => {
                        setData(result);
                        setChange(!change);
                        setSort("vol-dsc");
                      });
                    } else {
                      sortByVol(data, false, (result) => {
                        setData(result);
                        setChange(!change);
                        setSort("vol-asc");
                      });
                    }
                  }}
                >
                  <h6>
                    Vol
                    {sort == "vol-asc" ? (
                      <i className="fa fa-arrow-up" aria-hidden="true"></i>
                    ) : null}
                    {sort == "vol-dsc" ? (
                      <i className="fa fa-arrow-down" aria-hidden="true"></i>
                    ) : null}
                  </h6>
                </th>
              </tr>
            </thead>
            <tbody>
              {active != "fav"
                ? data?.map((d, index) => {
                    if (active.toUpperCase() != d.symbol.toUpperCase())
                      return (
                        <>
                          {active.toUpperCase() !== "BTC" &&
                          active.toUpperCase() !== "INR" &&
                          active.toUpperCase() !== "USDT" ? (
                            d["is_paired_custom_coin"] === 1 ? (
                              <CoinRow
                                {...d}
                                id={index * 5}
                                pairing_currency={active}
                                pc_price={paired_curency_price}
                                remoteCoins={remoteCoins}
                              />
                            ) : null
                          ) : d[
                              `is_paired_${
                                active.toLowerCase()
                              }`
                            ] === 1 ? (
                            <CoinRow
                              {...d}
                              id={index * 2 * 5}
                              pairing_currency={active}
                              pc_price={paired_curency_price}
                              remoteCoins={remoteCoins}
                            />
                          ) : null}
                        </>
                      );
                    else return <></>;
                  })
                : user_fav_pairing?.map((d, index) => (
                    <CoinRow {...coins[d]} key={index * 7} />
                    // console.log("fav_coin",)
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function CoinRow(props) {
  const history = useHistory();
  let price = 0;
  let coinStatus = false;
  let cp = props.pairing_currency.toUpperCase();
  let remoteArray = props.remoteCoins.find((item) => {
    if (item.currency_type == props.symbol && item.compare_currency == cp)
      return item;
  });
  coinStatus = remoteArray?.update_price;
  switch (props.pairing_currency.toUpperCase()) {
    case "INR":
      price = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 8,
        maximumSignificantDigits: 8,
      }).format(
        coinStatus
          ? getRound(props.inr_price)
          : props.current_price
          ? getRound(props.current_price)
          : 0.12
      );

      break;

    case "USDT":
      price = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 8,
        maximumSignificantDigits: 8,
      }).format(
        coinStatus
          ? getRound(props.usdt_price)
          : props.current_price
          ? getRound(props.current_price / props.pc_price.USDT)
          : 0.03
      );

      break;
    case "BTC":
      price = coinStatus
        ? getRound(props.btc_price)
        : props.current_price
        ? getRound(props.current_price / props.pc_price.BTC)
        : 0.03;
      // console.log("check symbol_2: ", props.symbol, price);
      break;
    case "DTBX":
      price = coinStatus
        ? getRound(props.rbc_price)
        : props.current_price
        ? getRound(props.current_price / props.pc_price.DTBX)
        : 0.03;
      break;
    default:
      price = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "USD",
      }).format(props.current_price ? getRound(props.current_price) : 0.03);
  }
  return (
    <tr
      id={props.id}
      style={{ cursor: "pointer", overflow: "hidden" }}
      onClick={() => {
        // dispatch(
        //   changeActiveCoin(props.symbol.toLowerCase(), props.active_tab, () =>
        localStorage.setItem(
          "precoinpair",
          `/exchange/${props.symbol.toLowerCase()}-${props.pairing_currency.toLowerCase()}`
        );
        history.push(
          `/exchange/${props.symbol.toLowerCase()}-${props.pairing_currency.toLowerCase()}`
          //   )
          // )
        );
      }}
    >
      <td>
        <div className="d-flex align-items-center">
          <img
            src={props.icon}
            alt={props.symbol}
            width="15"
            className="me-2"
            height="15"
          />
          <div className="">
            <span className="text-muted">
              {props.symbol}/{props.pairing_currency?.toUpperCase()}
            </span>
          </div>
        </div>
      </td>
      <td>
        <span className="fs-11">
          <span
            className={props.direction == "up" ? "text-success" : "text-danger"}
          >
            <i
              className={
                props.direction == "up" ? "fa fa-caret-up" : "fa fa-caret-down"
              }
            ></i>{" "}
            {getRound(props.price_change_percentage_1h)}%
          </span>
        </span>
      </td>
      <td className="text-end">
        <p className="no-margin text-fade mb-0">
          {props.pairing_currency?.toUpperCase() === "BTC" ? (
            <>
              <BiBitcoin size={12} />
              {price}
            </>
          ) : (
            price
          )}
        </p>
      </td>
    </tr>
  );
}
