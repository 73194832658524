import React, { useEffect, useState } from 'react'
import PaginationComponent from './PaginationComponent';
import { RiArrowUpDownFill } from "react-icons/ri";
import { N_getHarvestHistory } from '../redux/helpers/api_functions_new';
import { useSelector } from 'react-redux';

function InterestWithdrawHistory() {
    const { user } = useSelector((state) => state.AuthReducer);
    const token = user?.params ? user.params.token : user.token;

    const [history, setHistory] = useState(null);
    const [sortOrder, setSortOrder] = useState("desc"); // Default sorting order
    const [dateOrder, setDateOrder] = useState("desc");
    const [periodOrder, setPeriodOrder] = useState("desc");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const pageSize = 10; // Adjust this value based on your preference\

    useEffect(() => {
        N_getHarvestHistory(token, currentPage).then((d) => {
            if (d.status === 200) {
                setHistory(d.stake_data);
                setTotalPages(Math.ceil(d.total / pageSize));
            }
        });
    }, [currentPage]);

    const options = {
        hour: "2-digit",
        minute: "2-digit",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    };

    const handleFilteredData = () => {
        if (history && history.length > 0) {
            const sortedHistory = [...history].sort((a, b) => {
                if (sortOrder === "asc") {
                    return a.harvest - b.harvest;
                } else {
                    return b.harvest - a.harvest;
                }
            });

            setHistory(sortedHistory);
            // Toggle sorting order for the next click
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        }
    };

    const handleFilteredDateData = () => {
        if (history && history.length > 0) {
            const sortedDateHistory = [...history].sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);

                if (dateOrder === "asc") {
                    return dateA - dateB;
                } else {
                    return dateB - dateA;
                }
            });

            setHistory(sortedDateHistory);
            // Toggle sorting order for the next click
            setDateOrder(dateOrder === "asc" ? "desc" : "asc");
        }
    };


    const handleFilteredPeriodData = () => {
        if (history && history.length > 0) {
            const sortedPeriodHistory = [...history].sort((a, b) => {
                const periodA = parseInt(a.invest_time);
                const periodB = parseInt(b.invest_time);

                if (periodOrder === "asc") {
                    return periodA - periodB;
                } else {
                    return periodB - periodA;
                }
            });

            setHistory(sortedPeriodHistory);
            // Toggle sorting order for the next click
            setPeriodOrder(periodOrder === "asc" ? "desc" : "asc");
        }
    };

    const handlePageChange = (pageNumber) => {
        if (pageNumber < 0) return;
        setCurrentPage(pageNumber);
    };


    return (
        <>
            {history && history.length > 0 ? (
                <>
                    <div className="overflow-auto mt-3 mx-5">
                        <div className="table_scroll_div">
                            <table className="table global_table table-bordered">
                                <thead>
                                    <tr className="text-center">
                                        <th>No.</th>
                                        <th>Wallet Type</th>
                                        <th>Staking Source</th>
                                        <th>
                                            Interest Amount
                                            <RiArrowUpDownFill
                                                className="text-muted"
                                                style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={handleFilteredData}
                                            />
                                        </th>
                                        <th>
                                            Investment Period
                                            <RiArrowUpDownFill
                                                className="text-muted"
                                                style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={handleFilteredPeriodData}
                                            />
                                        </th>
                                        <th>
                                            Deposited Amount
                                            <RiArrowUpDownFill
                                                className="text-muted"
                                                style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={handleFilteredDateData}
                                            />
                                        </th>
                                        <th>
                                            Deposited At
                                            <RiArrowUpDownFill
                                                className="text-muted"
                                                style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={handleFilteredDateData}
                                            />
                                        </th>
                                        <th>
                                            Withdraw At
                                            <RiArrowUpDownFill
                                                className="text-muted"
                                                style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={handleFilteredDateData}
                                            />
                                        </th>
                                        <th>
                                            Earnings at Maturity
                                            <RiArrowUpDownFill
                                                className="text-muted"
                                                style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={handleFilteredDateData}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {history && history.length > 0
                                        ? history.map((item, key) => {
                                            return (
                                                <tr>
                                                    <td className="text-center">{key + 1}</td>
                                                    <td className="text-center">
                                                        {item.wallet_type}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.StakingSource.toUpperCase()}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.harvest}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.invest_time} days
                                                    </td>
                                                    <td className="text-center">
                                                        {item.staked_amount}
                                                    </td>
                                                    <td className="text-center">
                                                        {new Date(
                                                            item.createdAt
                                                        ).toLocaleDateString("en-IN", options)}
                                                    </td>
                                                    <td className="text-center">
                                                        {new Date(
                                                            item.createdAt
                                                        ).toLocaleDateString("en-IN", options)}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.MaturityEarning} DTBX
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <PaginationComponent
                        currentPage={currentPage}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                    />
                </>
            ) : (
                <div className="container mt-4">
                    <div className="alert alert-danger text-center text-black">
                        <strong>No Harvest History Found</strong>
                    </div>
                </div>
            )}
        </>
    )
}

export default InterestWithdrawHistory