import React,{useEffect} from 'react'
import Header from "./Header";
import Footer from './Footer';


export default function TermsConditions(props){
    useEffect(() => {
        document.title = "DTBX Terms & Conditions"
    }, [])
    return(
        <>
        <Header {...props} />
        <div className="wrap-privacy">
        <div className='container py-5'>
        <div style={{height: "15vh"}} className="d-block mx-auto">
            <h1>Terms & Conditions</h1>
            </div>
            <h2>1. Account Registration:</h2>
            <p>Users must provide accurate and up-to-date information during the registration process.
Each user is responsible for maintaining the confidentiality of their account credentials.
Users may only have one account and should not share account details.</p>


 <h2>2. Verification and Compliance</h2>
 <p>Users may be required to undergo identity verification procedures.
The exchange complies with relevant regulations and may request additional information to meet legal requirements.
Users must adhere to all applicable laws and regulations related to cryptocurrency trading.</p>
 <h2>3. Deposits and Withdrawals</h2>
 <p>Users are responsible for ensuring the accuracy of deposit addresses.
Deposits and withdrawals may be subject to fees, which will be clearly outlined.
Minimum and maximum deposit/withdrawal limits may apply.</p>
 <h2>4. Trading</h2>
 <p>Users must understand the risks associated with cryptocurrency trading.
The exchange may have trading fees, which will be detailed on the platform.
Market orders, limit orders, and other trading functionalities are subject to availability and system conditions.</p>
<h2>5. Security:</h2>
<p>Users are encouraged to enable two-factor authentication for added security.
The exchange employs security measures, but users must take precautions to secure their accounts.
Suspicious activities or security breaches should be reported promptly.</p>


 <h2>6. Privacy Policy:</h2>
 <p>The exchange outlines its privacy practices in a dedicated Privacy Policy.
Users' personal information is handled in accordance with the stated privacy policy.</p>



<h2>7. Prohibited Activities: </h2>
<p>Users must not engage in illegal activities, including money laundering, fraud, or market manipulation.
The exchange reserves the right to suspend or terminate accounts involved in suspicious or prohibited activities.</p>

 <h2>8. Service Availability:</h2>
 <p>The exchange strives to provide uninterrupted services, but downtime may occur for maintenance or other reasons.
Users are notified in advance of scheduled maintenance.</p>
 

<h2>9. Liability and Disclaimers: </h2>
<p>The exchange is not liable for losses incurred due to market fluctuations, system failures, or unforeseen events.
Users are responsible for their investment decisions and assume the associated risks.</p>



 <h2>10. Termination of Services:</h2>
 
 <p>The exchange reserves the right to suspend or terminate services to any user at its discretion.
Termination may occur for violations of terms, regulatory requirements, or other reasons.</p>



 <h2>11. Amendments to Terms and Conditions:</h2>
 <p>The exchange has the right to update or modify the terms and conditions.
Users are notified of changes, and continued use of the platform implies acceptance of the updated terms.</p>
 


<h2>12. Governing Law: </h2>
<p>The terms and conditions are governed by the laws of the jurisdiction in which the exchange operates.</p>



 <h2>13. Customer Support:</h2>
 <p>Users can contact customer support for assistance and dispute resolution.
The exchange outlines response times and support availability.</p>


 <h2>14. Miscellaneous:</h2>
 <p>Force majeure clauses, dispute resolution mechanisms, and other miscellaneous provisions may be included.
It's crucial for users to thoroughly read and understand the terms and conditions of any crypto exchange before engaging in trading activities.</p>
            </div>
        </div>
        <Footer />

        </>
    )
}