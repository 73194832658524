import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import Carousel from "react-grid-carousel";
import { FaCoins } from "react-icons/fa";
import { BiGitCompare } from "react-icons/bi";
import { HiCubeTransparent } from "react-icons/hi";
import { FiUser, FiGlobe, FiUsers } from "react-icons/fi";

import Header from "./Header";
import Footer from "./Footer";

export default function FastTrade() {
  const { wallet, paired_currency_price, coins } = useSelector(
    (state) => state.coinDBReducer
  );
  const [inrValue, setInrValue] = useState(0);

  useEffect(() => {
    // Convert the coins object values into an array
    const coinsArray = Object.values(coins);

    // Find the cryptocurrency with the symbol 'USDT'
    const usdtCrypto = coinsArray.find(crypto => crypto.symbol === 'USDT');

    if (usdtCrypto) {
      // Set the 'inrValue' state to the current price of 'USDT'
      setInrValue(usdtCrypto.current_price);
    }
  }, [coins]);

  return (
    <>
      <Header />
      <div className="page-content">
        <div class="tab_area">
          <div class="container custom_pills">
            <div class="nav nav-pills" id="nav-tab" role="tablist">
              <div
                class="nav-item nav-link active"
                href="#fast_trade"
                data-toggle="tab"
              >
                Fast Trade
              </div>
              <div class="nav-item nav-link" href="#p2p_data" data-toggle="tab">
                P2P
              </div>
            </div>
          </div>
        </div>
        <div className="tab-content">
          <div className="tab-pane fade active show" id="fast_trade">
            <div className="banner-section">
              <div className="banner_left"></div>
              <div className="banner_right"></div>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-12 col-lg-7 mb-3">
                    <div className="banner_left_inner">
                      <h2 className="bannertxt">Buy Crypto in One Click </h2>
                      <h3 className="mb-4">
                        {" "}
                        Pay with the currency and payment method of your choice.
                      </h3>

                      <div className="payement_method mt-5">
                        <h4 className="mb-3">
                          We support as many as 70+ payment methods.
                        </h4>
                        <img src="./img/master.svg" />
                        <img src="./img/visa.svg" />{" "}
                        <img src="./img/paypal.svg" />{" "}
                        <img src="./img/payement2.svg" />
                        <img src="./img/sepa.svg" />
                        <img src="./img/paymethod.svg" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-5">
                    <div class="buysell-card mt-2 mb-2">
                      <div class=" p-0">
                        <div
                          class="nav nav-pills nav-justified"
                          id="nav-tab"
                          role="tablist"
                        >
                          <div
                            class="nav-item nav-link active"
                            href="#buy-tab"
                            data-toggle="tab"
                          >
                            Buy
                          </div>
                          <div
                            class="nav-item nav-link"
                            href="#sell-tab"
                            data-toggle="tab"
                          >
                            Sell
                          </div>
                        </div>
                      </div>
                      <div class=" custom_scrollbar">
                        <div class="tab-content p-5">
                          <div class="tab-pane active" id="buy-tab">
                            <div>
                              <label className="small text-muted">
                                I Want to Spend
                              </label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  value=""
                                  placeholder="Limit: 10 -5,000"
                                />
                                <span class="input-group-text">
                                  <img
                                    src="./img/USDT.png"
                                    className="me-1"
                                    width={20}
                                  />
                                  USDT
                                </span>
                              </div>
                            </div>

                            <div>
                              <label className="small text-muted">
                                I Will Recieve
                              </label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control "
                                  value=""
                                  placeholder="Limit: 9.52 - 4,761.9"
                                />
                                <span class="input-group-text">
                                  {" "}
                                  <span class="input-group-text">
                                    <img
                                      src="./img/USD.png"
                                      className="me-1"
                                      width={20}
                                    />
                                    USD
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div>
                              <label className="small text-muted">
                                Pay With
                              </label>
                              <div
                                class="payement pointer"
                                data-bs-toggle="modal"
                                data-bs-target="#paymentmodal"
                              >
                                <img src="./img/visa.svg" />{" "}
                                <img src="./img/master.svg" /> Visa/Master Card
                              </div>
                            </div>

                            <div class="d-grid mt-2">
                              <button class="btn btn-success btn-lg">
                                BUY BTC
                              </button>
                            </div>
                            <span className="small-info">
                              Reference Price: 1 USDT ≈ {inrValue && `${inrValue} INR`}
                            </span>
                          </div>

                          <div class="tab-pane" id="sell-tab">
                            <div>
                              <label className="small text-muted">
                                I Want to Spend
                              </label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  value=""
                                  placeholder="Limit: 10 -5,000"
                                />
                                <span class="input-group-text">
                                  <img
                                    src="./img/USDT.png"
                                    className="me-1"
                                    width={20}
                                  />
                                  USDT
                                </span>
                              </div>
                            </div>

                            <div>
                              <label className="small text-muted">
                                I Will Recieve
                              </label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control "
                                  value=""
                                  placeholder="Limit: 9.52 - 4,761.9"
                                />
                                <span class="input-group-text">
                                  {" "}
                                  <span class="input-group-text">
                                    <img
                                      src="./img/USD.png"
                                      className="me-1"
                                      width={20}
                                    />
                                    USD
                                  </span>
                                </span>
                              </div>
                            </div>

                            <div>
                              <label className="small text-muted">
                                Pay With
                              </label>
                              <div
                                class="payement pointer"
                                data-bs-toggle="modal"
                                data-bs-target="#paymentmodal"
                              >
                                <img src="./img/visa.svg" />{" "}
                                <img src="./img/master.svg" /> Visa/Master Card
                              </div>
                            </div>

                            <div class="d-grid mt-2">
                              <button class="btn btn-danger btn-lg">
                                SELL BTC
                              </button>
                            </div>
                            <span className="small-info">
                              Reference Price:1 USDT ≈ 1.05 USD
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="page_spacer">
              <div className="container">
                <div className="para_heading">A Faster Way to Buy Crypto</div>
                <p>Buy crypto in just 3 steps:</p>
                <div className="row d-flex align-center justify-content-center mt-5">
                  <div className="col-lg-4 col-md-6 col-12 padding_0 fast_trade">
                    <div className="card bg-transparent border-0">
                      <div className="card-body padding_5">
                        <div class="text-center mb-3">
                          <div class="vtimeline_no active"> 1</div>
                          <div class="vtimeline_right"></div>
                        </div>

                        <h4 className="">Register</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 padding_0">
                    <div className="card bg-transparent border-0 fast_trade ">
                      <div className="card-body padding_5">
                        <div class="text-center mb-3">
                          <div class="vtimeline_left"></div>
                          <div class="vtimeline_no "> 2</div>
                          <div class="vtimeline_right"></div>
                        </div>

                        <h4>Verify Your Identity</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 padding_0">
                    <div className="card bg-transparent border-0 fast_trade">
                      <div className="card-body padding_5">
                        <div class="text-center mb-3">
                          <div class="vtimeline_left"></div>
                          <div class="vtimeline_no "> 3</div>
                        </div>

                        <h4>Bind Your Card</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="features_section">
              <div className="container  faq">
                <div className="col-lg-12 text-center">
                  <h2 class="para_heading"> FAQs </h2>
                </div>
                <div className="col-lg-12">
                  <div
                    class="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingOne">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          How do I buy cryptocurrency with a bank card?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          The DTBX Affiliate Program is looking for partners who
                          share the same mission and values. Affiliates are
                          granted the right to create a special referral link
                          which will bind any user who registers through it to
                          their accounts. Earn trading fee commissions from all
                          users you invite, so long as they use your referral
                          link.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingTwo">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          How can I qualify to buy crypto with a bank card?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <ul>
                            <li>
                              1. Complete Advanced Identity Verification on
                              DTBX.
                            </li>
                            <li>
                              2. Use a Visa or MasterCard that supports 3D
                              Secure (3DS).
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingThree">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          Which bank cards are supported?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          We support credit, debit, and prepaid cards issued by
                          Visa and MasterCard.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tab-pane fade active show" id="p2p_data"></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
