import io from "socket.io-client";
const SOCKET_URI = "localhost:5007"
const PROD_SOCKET_URI = "wss://socket.dtbx.exchange"
function createSocketClient(access_token) {
  const socket = io("wss://socket.dtbx.exchange", {
    auth: {
      token: access_token, //kujgwvfq-a-ghosttown-z-1fhhup0p6
    },
  });
  return socket;
}
export default createSocketClient;
