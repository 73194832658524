import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { N_getStakeHistory } from '../redux/helpers/api_functions_new';
import { RiArrowUpDownFill } from "react-icons/ri";
import { round } from '../redux/helpers/Math';
import PaginationComponent from './PaginationComponent';

function StakingHistoryComponent() {
    const { user } = useSelector((state) => state.AuthReducer);
    const token = user?.params ? user.params.token : user.token;

    const options = {
        hour: "2-digit",
        minute: "2-digit",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    };


    const [history, setHistory] = useState(null);
    const [dateOrder, setDateOrder] = useState("desc");
    const [periodOrder, setPeriodOrder] = useState("desc");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const pageSize = 10; // Adjust this value based on your preference\


    useEffect(() => {
        N_getStakeHistory(token, currentPage).then((d) => {
            if (d.status === 200) {
                if (d.stake_data) {
                    setHistory(d.stake_data);
                    setTotalPages(Math.ceil(d.total / pageSize));
                }
            }
        });
    }, [currentPage]);

    const handlePageChange = (pageNumber) => {
        if (pageNumber < 0) return;
        setCurrentPage(pageNumber);
    };

    const handleFilteredDateData = () => {
        if (history && history.length > 0) {
            const sortedDateHistory = [...history].sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);

                if (dateOrder === "asc") {
                    return dateA - dateB;
                } else {
                    return dateB - dateA;
                }
            });

            setHistory(sortedDateHistory);
            // Toggle sorting order for the next click
            setDateOrder(dateOrder === "asc" ? "desc" : "asc");
        }
    };

    const handleFilteredPeriodData = () => {
        if (history && history.length > 0) {
            const sortedPeriodHistory = [...history].sort((a, b) => {
                const periodA = parseInt(a.invest_time);
                const periodB = parseInt(b.invest_time);

                if (periodOrder === "asc") {
                    return periodA - periodB;
                } else {
                    return periodB - periodA;
                }
            });

            setHistory(sortedPeriodHistory);
            // Toggle sorting order for the next click
            setPeriodOrder(periodOrder === "asc" ? "desc" : "asc");
        }
    };


    return (
        <>
            {history && history.length > 0 ? (
                <>
                    <div className="table_scroll_div">
                        <div className="overflow-auto mt-3 mx-5">
                            <table className="table global_table table-bordered">
                                <thead>
                                    <tr className="text-center">
                                        <th>No.</th>
                                        <th>Wallet Type</th>
                                        <th>Invested Amount</th>
                                        <th>Percentage</th>
                                        <th>
                                            Investment Period
                                            <RiArrowUpDownFill
                                                className="text-muted"
                                                style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={handleFilteredPeriodData}
                                            />
                                        </th>
                                        <th>
                                            Deposited At
                                            <RiArrowUpDownFill
                                                className="text-muted"
                                                style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={handleFilteredDateData}
                                            />
                                        </th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {history && history.length > 0
                                        ? history.map((item, key) => {
                                            return (
                                                <tr className="text-center">
                                                    <td>{key + 1}</td>
                                                    <td>{item.wallet_type}</td>
                                                    <td>{item.invest_amount}</td>
                                                    <td>{round(item.percent)} %</td>
                                                    <td>{item.invest_time}</td>
                                                    <td>
                                                        {new Date(
                                                            item.createdAt
                                                        ).toLocaleDateString("en-IN", options)}
                                                    </td>
                                                    <td>
                                                        {!item.stake_completed === false ? (
                                                            <button className="btn bg-danger bg-gradient text-body">
                                                                CLOSE
                                                            </button>
                                                        ) : (
                                                            <button className="btn bg-success bg-gradient text-body">
                                                                OPEN
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        : null}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <PaginationComponent
                        currentPage={currentPage}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                    />
                </>
            ) : (
                <div className="container mt-4">
                    <div className="alert alert-danger text-center text-black">
                        <strong>No Stake History Found</strong>
                    </div>
                </div>
            )}
        </>
    )
}

export default StakingHistoryComponent