import { useDispatch, useSelector } from "react-redux";
import { user_logout } from "../redux/actions/authActions";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  CiMenuFries,
  CiViewBoard,
  CiMoneyCheck1,
  CiMoneyBill,
  CiLaptop,
} from "react-icons/ci";
import { IoNotificationsOutline } from "react-icons/io5";
import { getCoinRate, getRound } from "../redux/helpers/helper_functions";
import LogoImage from "../../public/main.png";
import { TbHistoryToggle } from "react-icons/tb";

export default function Header(props) {
  const dispatch = useDispatch();
  const { user, profile } = useSelector((state) => state.coinDBReducer);
  const { wallet, paired_curency_price, coins, wallet_loading } = useSelector(
    (state) => state.coinDBReducer
  );
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const [total_inr, setTotalInr] = useState(0);
  const [total_usdt, setTotalUsdt] = useState(0);

  const isLoggedIn = true;

  async function backRouter() {
    dispatch(
      user_logout(() => {
        props.history?.replace("/login");
      })
    );
    document.location.reload();
  }

  useEffect(() => {
    if (!wallet_loading) {
      getWallets();
    }
  }, [wallet, coins, paired_curency_price]);

  function getWallets() {
    let total = 0;
    wallet &&
      Object.keys(wallet).map((res, i) => {
        let wallet_type = wallet[res]?.symbol.toUpperCase();
        let rate = getCoinRate(coins, wallet_type);
        let inr_val = rate * getRound(wallet[res]?.balance);
        total = total + parseFloat(inr_val);
      });
    setTotalInr(getRound(total));
    setTotalUsdt(getRound(total / paired_curency_price.USDT));
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-dark">
        <Link className="navbar-brand" href="/">
          <img src={LogoImage} alt="Logo" className="" height="30" />
        </Link>
        {isLoggedIn ? (
          <li className="nav-item d-none d-lg-block">
            <button className="btn btn-primary">Assets Overview</button>
          </li>
        ) : null}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <CiMenuFries size={30} style={{ color: "white" }} />
        </button>
        <div
          className="offcanvas offcanvas-end bg-dark"
          tabIndex="-1"
          id="navbarNav"
          aria-labelledby="navbarNavLabel"
        >
          <div className="offcanvas-header">
            <button
              type="button"
              className="btn-close bg-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body justify-content-lg-end bg-dark">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link text-white" href="#">
                  Market
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  More
                </a>
                <div
                  className="dropdown-menu bg-dark"
                  aria-labelledby="navbarDropdown"
                >
                  <Link className="dropdown-item d-flex justify-content-start">
                    <div className="d-flex justify-content-center align-items-center">
                      <CiViewBoard size={25} className="text-white" />
                      <span className="text-white">Referral Program</span>
                    </div>
                  </Link>
                  <Link className="dropdown-item d-flex justify-content-start">
                    <div className="d-flex justify-content-center align-items-center">
                      <CiViewBoard size={25} className="text-white" />
                      <span className="text-white">Reward Hub</span>
                    </div>
                  </Link>
                </div>
              </li>
              {isLoggedIn ? (
                <>
                  <li className="nav-item dropdown">
                    <a
                      class="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Assets
                    </a>
                    <div
                      className="dropdown-menu bg-dark"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link className="dropdown-item d-flex justify-content-start">
                        <div className="d-flex justify-content-center align-items-center">
                          <CiMoneyCheck1 size={25} className="text-white" />
                          <span className="text-white">Buy Crypto</span>
                        </div>
                      </Link>
                      <Link className="dropdown-item d-flex justify-content-start">
                        <div className="d-flex justify-content-center align-items-center">
                          <CiMoneyCheck1 size={25} className="text-white" />
                          <span className="text-white">Assets Deposit</span>
                        </div>
                      </Link>
                      <Link className="dropdown-item d-flex justify-content-start">
                        <div className="d-flex justify-content-center align-items-center">
                          <CiMoneyCheck1 size={25} className="text-white" />
                          <span className="text-white">Assets WithDraw</span>
                        </div>
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      class="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Orders
                    </a>
                    <div
                      className="dropdown-menu bg-dark"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link className="dropdown-item d-flex justify-content-start">
                        <div className="d-flex justify-content-center align-items-center dropdown-body-item">
                          <CiLaptop size={25} className="text-white" />
                          <span className="text-white">Spot Orders</span>
                        </div>
                      </Link>
                      <Link className="dropdown-item d-flex justify-content-start">
                        <div className="d-flex justify-content-center align-items-center">
                          <CiMoneyBill size={25} className="text-white" />
                          <span className="text-white">Buy Crypto Orders</span>
                        </div>
                      </Link>
                      <div class="dropdown-divider"></div>
                      <Link className="dropdown-item d-flex justify-content-start">
                        <div className="d-flex justify-content-center align-items-center">
                          <TbHistoryToggle size={25} className="text-white" />
                          <span className="text-white">
                            Sport Trade History
                          </span>
                        </div>
                      </Link>
                    </div>
                  </li>
                </>
              ) : null}
              <li className="nav-item">
                <a className="nav-link text-white" href="#">
                  Company
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-white" href="#">
                  Contact
                </a>
              </li>

              {isLoggedIn ? (
                <li className="nav-item">
                  <a
                    className="nav-link "
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="circle_backdrop_outline border border-white">
                      <span className="text-white">RA</span>
                    </div>
                  </a>
                  <div
                    className="dropdown-menu bg-dark"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link className="dropdown-item">
                      <div className="dropdown-body-item">
                        <h2 className="text-white">Rajaji00@byom.com</h2>
                      </div>
                    </Link>
                    <div class="dropdown-divider"></div>
                    <Link className="dropdown-item">
                      <div className="dropdown-body-item">
                        <span className="text-white">Account Security</span>
                      </div>
                    </Link>
                    <Link className="dropdown-item">
                      <div className="dropdown-body-item">
                        <span className="text-white">
                          Identity Verification
                        </span>
                      </div>
                    </Link>
                    <div class="dropdown-divider"></div>
                    <div>
                      {" "}
                      <a
                        className="dropdown-item cursor_pointer border-top"
                        onClick={() => {
                          backRouter();
                        }}
                      >
                        <div className="dropdown-body-item text-center text-primary">
                          Log Out
                        </div>
                      </a>
                    </div>
                  </div>
                </li>
              ) : null}

              {isLoggedIn ? (
                <li className="nav-item">
                  <a
                    className="nav-link text-white"
                    data-bs-toggle="offcanvas"
                    href="#notification_toggle"
                    role="button"
                    aria-controls="notification_toggle"
                  >
                    <div className="circle_backdrop">
                      <i className="fa fa-bell-o fs-6"></i>
                    </div>
                  </a>
                </li>
              ) : null}

              {!isLoggedIn ? (
                <li className="nav-item d-none d-lg-block">
                  <button className="btn btn-outline-light">Sign In</button>
                </li>
              ) : null}
              {!isLoggedIn ? (
                <li className="nav-item">
                  <button className="btn btn-primary">Get Started</button>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </nav>

      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="notification_toggle"
        aria-labelledby="notification_toggleLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="notification_toggleLabel">
            Notifications
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div>news1</div>
        </div>
      </div>
    </>
  );
}
