import React, { useState } from "react";
import Header from "./HomeComp/Header";
import InterestWithdrawHistory from "./StakingHistory/InterestWithdrawHistory";
import StakingHistoryComponent from "./StakingHistory/StakingHistoryComponent";

function StakingHistory(props) {
  const [activeTab, setActiveTab] = useState("harvest_history");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <Header {...props} />

      <>
        <div className="d-flex justify-content-center">
          <div className="row">
            <div className="activity_panel mb-4">
              <div className="tab_width">
                <div className="custom_pills">
                  <div className="row">
                    <div className="nav nav-pills" id="nav-tab" role="tablist">
                      <div
                        className={`nav-item nav-link ${activeTab === "harvest_history" ? "active" : ""
                          } fs-14`}
                        style={{ height: "35px", whiteSpace: "nowrap" }}
                        onClick={() => handleTabClick("harvest_history")}
                      >
                        Interest Withdraw History
                      </div>
                      <div
                        className={`nav-item nav-link ${activeTab === "staking_history" ? "active" : ""
                          } fs-14`}
                        style={{ height: "35px" }}
                        onClick={() => handleTabClick("staking_history")}
                      >
                        Staking History
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-content">
          <div
            className={`tab-pane fade ${activeTab === "harvest_history" ? "show active" : ""
              }`}
            id="harvest_history"
          >
            <InterestWithdrawHistory />
          </div>

          <div
            className={`tab-pane fade ${activeTab === "staking_history" ? "show active" : ""
              }`}
            id="staking_history"
          >
            <StakingHistoryComponent />
          </div>
        </div>
      </>
    </>
  );
}

export default StakingHistory;
