export default function Newsletter() {
    return (
      <div className="mt-5 p-5 text-white newsletter-bg-img">
        <div className="container text-center w-lg-50">
          <h6 className="fs-4 mb-3">Ready to start trading cryptocurrency</h6>
          <h1 className="display-4 fw-bold mb-4">
            New users can earn 4 dtbx in crypto rewards
          </h1>
          {/* <p className="mb-4">
            Subscribe to our newsletter and stay updated on the latest trends and
            opportunities in the crypto market.
          </p> */}
          {/* <div className="input-group mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="Enter your email"
              aria-label="Recipient's email"
              aria-describedby="button-addon2"
            />
            <button className="btn btn-primary" type="button" id="button-addon2">
              Subscribe
            </button>
          </div> */}
          {/* <p className="small">
            We respect your privacy. Unsubscribe at any time.
          </p> */}
        </div>
      </div>
    );
  }