import React from "react";
import Loader from "./components/Loader";
import { useSelector, useDispatch } from "react-redux";
import { round } from "./redux/helpers/Math";
export default function TradeTab({ props, coin, coinname, webData, trade_history, trade_loading }) {
  let harr = [];
  function reverseArr(input) {
    let ret = new Array();
    for (var i = input.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    const ret1 = ret.sort((a, b) => b.timestamp - a.timestamp);
    return ret1;
  }
  if (
    trade_history &&
    trade_history[coinname] &&
    trade_history[coinname].length > 0
  )
    harr = reverseArr(trade_history[coinname]);
  // console.log(harr);
  return (
    <div className="card">
      <div className="card-header">Trade History</div>
      <div className="card-body p-0">
        <div className="trade_height custom_scrollbar">
          <table className="exchange_tbl order-book-table ">
            <thead className="sticky_thead">
              <tr>
                <th>
                  <h6>Price</h6>
                </th>
                <th className="">
                  <h6>Volume</h6>
                </th>

                <th className="text-end">
                  <h6>Total</h6>
                </th>
              </tr>
            </thead>
            <tbody className="sell">
              {trade_loading ? (
                <Loader />
              ) : harr?.length != 0 ? (
                harr ? (
                  harr?.map((d, index, arr) => (
                    <TradeRow
                      isSell={
                        index === 9
                          ? false
                          : parseFloat(d.raw_price) >=
                            parseFloat(harr ? arr[index + 1]?.raw_price : 0)
                            ? false
                            : true
                      }
                      price={Number(d.raw_price)}
                      volume={Number(d.volume)}
                      timestamp={d.timestamp}
                      key={index}
                    />
                  ))
                ) : (
                  <td className="text-center py-5 text-muted" colspan="3">
                    <div>
                      <i class="fa fa-folder-open-o fs-2"></i>
                    </div>
                    <div className="fs-12">No Trade History Found !</div>
                  </td>
                )
              ) : (
                <td className="text-center py-5 text-muted" colspan="3">
                  <div>
                    <i class="fa fa-folder-open-o fs-2"></i>
                  </div>
                  <div className="fs-12">No Trade History Found !</div>
                </td>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function TradeRow(props) {
  const total = round(props.price) * round(props.volume).toFixed(2);
  return (
    <tr>
      <td>
        <span className={` ${props.isSell ? "text-green" : "text-red"}`}>
          {props.price}
        </span>
      </td>
      <td>
        {props.volume.toString().length > 6
          ? props.volume.toString().substr(0, 8)
          : props.volume}
      </td>
      <td className="text-end">
        {total && total.toFixed(2)}
      </td>
    </tr>
  );
}
