import HeroImage from "../../public/hero.png";

export default function Hero() {
    return (
      <div className="row mt-5 mobile-line-bg-img">
        {/* Hero Content */}
        <div className="col-md-6">
          <div className="text-white d-flex flex-column justify-content-center align-items-start">
            <h1 className="display-3 mb-4 fw-bold px-3 py-2 m-0 font-family-Sofia w-lg-75">
              A Trusted and Secure Cryptocurrency Exchange.
            </h1>
            <span className="lead mb-4 px-3 py-2 fs-3 text-center text-lg-start w-lg-75 font-weight-light">
              Your guide to the world of an financial system. Get started with the
              easiest and most secure platform to buy and trade crytptocurrency
            </span>
          </div>
        </div>
        {/* Hero Image */}
        <div className="col-md-6">
          <div>
            <img className="hero-image" src={HeroImage} alt="" />
          </div>
        </div>
      </div>
    );
  }