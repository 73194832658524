import { useState } from "react";
import { CiBank } from "react-icons/ci";
import { HiOutlineUserAdd } from "react-icons/hi";
import { TfiWallet } from "react-icons/tfi";
import DeviceImage from "../../public/Device.png";
import IllusImage from "../../public/Illus.png";

// export default function Usage() {
//     const [activeTab, setActiveTab] = useState("buy");

//     const handleTabChange = (tab) => {
//       setActiveTab(tab);
//     };

//     return (
//       <div className="mt-5 padding-bottom">
//         <div className="container">
//           <div className="row">
//             <div className="col order-2">
//               <div
//                 className="container-fluid d-flex justify-content-center mt-5 w-fit"
//                 style={{ backgroundColor: "#343a40" }}
//               >
//                 <div className="row w-100 justify-content-center">
//                   <div className="col-lg-6 w-100">
//                     <ul className="nav  nav-justified justify-content-center">
//                       <li className="nav-item">
//                         <button
//                           className={`nav-link ${
//                             activeTab === "buy"
//                               ? "text-primary"
//                               : "text-secondary"
//                           } bg-transparent border-0 fw-bold fs-3`}
//                           onClick={() => handleTabChange("buy")}
//                         >
//                           Buy
//                         </button>
//                       </li>
//                       <li className="nav-item">
//                         <button
//                           className={`nav-link ${
//                             activeTab === "sell"
//                               ? "text-primary"
//                               : "text-secondary"
//                           } bg-transparent border-0 fw-bold fs-3`}
//                           onClick={() => handleTabChange("sell")}
//                         >
//                           Sell
//                         </button>
//                       </li>
//                     </ul>
//                     {/* Content for the selected tab goes here */}
//                     {activeTab === "buy" && (
//                       <div className="row mt-4">
//                         <h4 className="text-light fs-6">1 BTC is roughly</h4>
//                         <div className="d-flex flex-column flex-lg-row justify-content-between">
//                           <h2 className="text-white nowrap fs-4">
//                             $53,256.20 USD
//                           </h2>
//                           <p className="text-secondary nowrap">
//                             Claim <span className="text-white">$10</span> in
//                             rewards
//                           </p>
//                         </div>
//                       </div>
//                     )}

//                     {activeTab === "sell" && <div>Sell Content Goes Here</div>}
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="text-white  col-lg order-1 order-lg-1 d-flex flex-column align-items-lg-center justify-content-lg-center">
//               <div className="container ">
//                 <h1 className="fw-bold display-4 mb-4">
//                   One click, instant payout with credit or debit card
//                 </h1>
//                 <p className=" fs-4 mb-4">
//                   Become a crypto owner in minutes using your debir or credit card
//                   and quickly purchase top cryptocurrencies.
//                 </p>
//               </div>
//               <div className="container half-circle-bg">
//                 <div className="d-flex justify-align-content-lg-start space-y-1 ">
//                   <div className="d-flex justify-content-center align-items-center">
//                     <div className="usage-icon">
//                       <HiOutlineUserAdd size={35} className="card-icon-color1" />
//                     </div>
//                     <span className="text-white px-4 lg-text sm-text text-nowrap fw-bold">
//                       Create an account
//                     </span>
//                   </div>
//                 </div>
//                 <div className="d-flex justify-content-lg-center space-y-1">
//                   <div className="d-flex justify-content-center align-items-center">
//                     <div className="usage-icon">
//                       <CiBank size={35} className="card-icon-color2" />
//                     </div>
//                     <span className="text-white px-4 lg-text sm-text text-nowrap fw-bold">
//                       Link your bank account
//                     </span>
//                   </div>
//                 </div>
//                 <div className="d-flex justify-content-lg-end space-y-1">
//                   <div className="d-flex justify-content-center align-items-center">
//                     <div className="usage-icon">
//                       <TfiWallet size={35} className="card-icon-color3" />
//                     </div>
//                     <span className="text-white px-4 lg-text sm-text text-nowrap fw-bold">
//                       Start Buying & Selling
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }

export default function Usage() {
  const [activeTab, setActiveTab] = useState("buy");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="mt-5 padding-bottom">
      <div className="container">
        <div className="row">
          <div className="col order-2">
            <div className="container-fluid d-flex justify-content-center mt-5 align-items-center h-100">
              <div className="row w-100 justify-content-center">
                <div className="col-lg-6 w-100">
                  <img src={IllusImage} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="text-white  col-lg order-1 order-lg-1 d-flex flex-column align-items-lg-center justify-content-lg-center">
            <div className="container ">
              <h1 className="fw-bold display-4 mb-4">
                One click, instant payout with credit or debit card
              </h1>
              <p className=" fs-4 mb-4">
                Become a crypto owner in seconds at DTBX exchange using your
                debit or credit card and quickly purchase top cryptocurrencies.
              </p>
            </div>
            <div className="container half-circle-bg">
              <div className="d-flex justify-align-content-lg-start space-y-1 ">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="usage-icon">
                    <HiOutlineUserAdd size={35} className="card-icon-color1" />
                  </div>
                  <span className="text-white px-4 lg-text sm-text text-nowrap fw-bold">
                    Create an account
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-lg-center space-y-1">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="usage-icon">
                    <CiBank size={35} className="card-icon-color2" />
                  </div>
                  <span className="text-white px-4 lg-text sm-text text-nowrap fw-bold">
                    Link your bank account
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-lg-end space-y-1">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="usage-icon">
                    <TfiWallet size={35} className="card-icon-color3" />
                  </div>
                  <span className="text-white px-4 lg-text sm-text text-nowrap fw-bold">
                    Start Buying & Selling
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
