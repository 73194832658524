import React, { useRef, useState } from "react";
import Header from "./HomeComp/Header";
import { Link } from "react-router-dom";
import {
  isCpass,
  isEmail,
  isNum,
  isPass,
} from "./redux/helpers/form-validator.functions";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { NotificationManager } from "react-notifications";
import { useGoogleLogin } from "@react-oauth/google";
// import { NotificationManager } from "react-notifications";
import { AUTH_LOGIN } from "./redux/constant";
// import { ConnectButton } from "@rainbow-me/rainbowkit";
import {
  N_resendOTP,
  N_sendOTP,
  N_userRegister,
  N_verifyCAPTCHA,
  N_verifyOTPNew,
  socialLogin,
} from "./redux/helpers/api_functions_new";
import ReCAPTCHA from "react-google-recaptcha";
// import { namehash } from "viem";

function checkEmailFormat(email) {
  const hasPlusSymbol = email.includes('+');
  const atIndex = email.indexOf('@');

  if (atIndex === -1) {
    // If there is no '@' symbol, the email is not in a valid format
    return true;
  }

  const dotCountBeforeAt = email.substring(0, atIndex).split('.').length - 1;

  return hasPlusSymbol || dotCountBeforeAt > 1;
}

export default function Register(props) {
  const captchaRef = useRef(null)
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState();
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState();
  const [password, setPassword] = useState();
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Password must contain one uppercase letter, one numerical value, and be at least 8 characters long."
  );
  const [confPassword, setConfPassword] = useState();
  const [activetab, setActivetab] = useState(0);
  const [referral_code, setReferral] = useState(
    props?.match?.params?.id ? props?.match?.params?.id : ""
  );
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState();
  const [eye, seteye] = useState(false);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  if (isLoggedIn) props.history?.replace("/");
  const hsPassword = (_id) => {
    var _idv = document.querySelector("#" + _id);
    if (_idv.getAttribute("type") == "text") {
      _idv.setAttribute("type", "password");
      seteye(false);
    } else {
      _idv.setAttribute("type", "text");
      seteye(true);
    }
  };

  const otpSend = async (e) => {
    e.preventDefault()
    const token = await captchaRef.current.executeAsync()
    captchaRef.current.reset()

    // N_verifyCAPTCHA(token).then((res) => {
    //   if (res.status === 200) {
    //     if ((isEmail(email) || isNum(phone)) && isPass(password)) {
    //       if (agree) {
    //         // $(".main_terms").removeClass("border-danger");
    //         setLoading(true);
    //         N_userRegister(email, phone, password, referral_code)
    //           .then((res) => {
    //             if (res.status === 200) {
    //               NotificationManager.success(res.message);
    //               setIsOTPSent(true);
    //             } else {
    //               NotificationManager.error(res.message);
    //             }
    //             setLoading(false);
    //           })
    //           .catch((e) => {
    //             console.log("n-user_reg err", e);
    //           });
    //       } else {
    //         NotificationManager.error("Please Agree with term condition !");
    //         // $(".main_terms").addClass("border-danger");
    //       }
    //     } else {
    //       NotificationManager.error("Please fill all data!");
    //     }
    //   }
    // }).catch((error) => {
    //   console.log(error)
    // })
    const checkEmail = checkEmailFormat(email)

    if (checkEmail) {
      NotificationManager.error("Invalid Email");
      return
    } else {
      if ((isEmail(email) && isPass(password))) {
        if (agree) {
          // $(".main_terms").removeClass("border-danger");
          setLoading(true);
          N_userRegister(email, phone, password, referral_code, token)
            .then((res) => {
              if (res.status === 200) {
                NotificationManager.success(res.message);
                setIsOTPSent(true);
              } else if (res.status === 429) {
                NotificationManager.error(res.message)
              } else {
                NotificationManager.error(res.message);
              }
              setLoading(false);
            })
            .catch((e) => {
              console.log("n-user_reg err", e);
            });
        } else {
          NotificationManager.error("Please Agree with term condition !");
          // $(".main_terms").addClass("border-danger");
        }
      } else {
        NotificationManager.error("Please fill all data correctly!");
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    N_verifyOTPNew(email, phone, otp).then((res) => {
      if (res.status == 200) {
        NotificationManager.success(res.message);
        console.log(res);
        dispatch({ type: AUTH_LOGIN, data: res });
        props.history?.replace("/AccountVerification");
      } else {
        NotificationManager.error(res.message);
      }
    });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      let parent_ref_code;
      if (referral_code) parent_ref_code = referral_code
      socialLogin(codeResponse.access_token, parent_ref_code).then((res) => {
        if (res.status) {
          NotificationManager.success(res.message);
          dispatch({ type: AUTH_LOGIN, data: res });
          props.history?.replace("/");
        } else {
          NotificationManager.success(res.message);
        }
      });
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  // create a regex function to check the password contains One Uppercase,one numerical value and length of 8 characters
  const validatePassword = (password) => {
    const passwordRegex = /(?=^.{8,15}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[0-9])(?=.*[@_#*&.])(?=.*[a-z]).*$/;

    const isValid = passwordRegex.test(password);

    return isValid;
  };

  const handlePassword = (e) => {
    const newPassword = e.target.value;

    const isValid = validatePassword(newPassword);

    if (!isValid) {
      setIsPasswordValid(false);
      setPassword(newPassword);
    }

    if (isValid) {
      setPassword(newPassword);
      setIsPasswordValid(true);
    }
  };

  return (
    <>
      <Header {...props} />

      <div class="container-fluid">
        <div className="page-content">
          <div class="row no-gutter">
            <div class="col-md-6 d-none d-lg-block login_left_bg">
              <div className="d-flex justify-content-center align-items-center login_page_minheight">
                <div class="text-center">
                  <img
                    src="/img/new-user-registration.png"
                    className="register-img"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="d-flex align-items-center login_page_minheight">
                <div class="container">
                  <div class="row">
                    {isOTPSent ? (
                      <div class="m-auto login_window signupform cstm_form">
                        <div className="page-content-title">
                          <h3 className="mb-lg-5">
                            Enter the 6-digit verification code we sent to{" "}
                            {email ? email : phone}
                          </h3>
                          <h6>For security, we need to verify it's you.</h6>
                        </div>
                        <form className="" method="post" id="signupform">
                          <div className="form-group">
                            <small id="msg">Error Message</small>
                          </div>
                          <div className={`form-group`}>
                            <div className="input-group">
                              <input
                                type="number"
                                className="form-control"
                                name="user_otp"
                                id="user_otp"
                                required=""
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                placeholder="Enter Verfication Code"
                              />
                              <span className="input-group-text">
                                <div className="">
                                  <div className="">
                                    <button
                                      className="btn border-0 text-primary border-start rounded-0"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        N_resendOTP(email, phone).then(
                                          (res) => {
                                            if (res.status == 200) {
                                              NotificationManager.success(
                                                res.message
                                              );
                                            } else {
                                              NotificationManager.error(
                                                res.message
                                              );
                                            }
                                          }
                                        );
                                      }}
                                    >
                                      Resend
                                    </button>
                                  </div>
                                </div>
                              </span>
                            </div>
                          </div>
                          <div className="d-grid mt-3">
                            <button
                              type="button"
                              id="btn_submit"
                              className="btn login_btn"
                              onClick={(e) => {
                                onSubmit(e);
                              }}
                            >
                              {loading ? (
                                <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                              ) : null}
                              <span id="reg">Verify</span>
                            </button>
                          </div>
                        </form>
                      </div>
                    ) : (
                      <div class="m-auto login_window signupform cstm_form">
                        <div className="page-content-title">
                          <h3 className="mb-lg-5">Create Account</h3>
                        </div>
                        <div className="custom_pills">
                          <nav
                            className="nav nav-pills mb-3"
                            id="myTab"
                            role="tablist"
                          >
                            <button
                              className="nav-link active"
                              id="home-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#home"
                              type="button"
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                              style={{ height: "28px" }}
                              onClick={() => {
                                setActivetab(0);
                              }}
                            >
                              Email
                            </button>

                            {/* <button
                              className="nav-link"
                              id="profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#profile"
                              style={{ height: "28px" }}
                              type="button"
                              role="tab"
                              aria-controls="profile"
                              aria-selected="false"
                              onClick={() => {
                                setActivetab(1);
                              }}
                            >
                              Phone Number
                            </button> */}
                          </nav>
                        </div>

                        <form className="" method="post" id="signupform">
                          <div className="form-group">
                            <small id="msg">Error Message</small>
                          </div>
                          {activetab == 0 ? (
                            <div className={`form-group`}>
                              <input
                                type="email"
                                name="user_email"
                                autoComplete="off"
                                readOnly
                                onFocus={(e) => {
                                  e.target.removeAttribute("readOnly");
                                }}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="form-control"
                                id="user_email"
                                placeholder="Email"
                              />
                            </div>
                          ) : (
                            null
                            // <div className={`form-group`}>
                            //   <input
                            //     type="phone"
                            //     name="user_phone"
                            //     autoComplete="off"
                            //     readOnly
                            //     onFocus={(e) => {
                            //       e.target.removeAttribute("readOnly");
                            //     }}
                            //     value={phone}
                            //     onChange={(e) => setPhone(e.target.value)}
                            //     className="form-control"
                            //     id="user_phone"
                            //     placeholder="Mobile Number"
                            //   />
                            // </div>
                          )}

                          <div className={`form-group`}>
                            <div className="input-group">
                              <input
                                type="password"
                                className="form-control"
                                name="user_password"
                                value={password}
                                // onChange={(e) => setPassword(e.target.value)}
                                onChange={handlePassword}
                                id="pass"
                                placeholder="Choose Password"
                              />
                              <span className="input-group-text">
                                <a
                                  href="#view_qr"
                                  className=""
                                  onClick={(e) => {
                                    hsPassword("pass");
                                  }}
                                >
                                  {eye ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="#666"
                                      className="bi bi-eye-slash-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                      <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="#666"
                                      className="bi bi-eye-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                    </svg>
                                  )}
                                </a>
                              </span>
                            </div>
                            <div className="container mt-3">
                              {isPasswordValid && (
                                <div
                                  className="alert alert-primary text-black"
                                  role="alert"
                                >
                                  {"Password is valid"}
                                </div>
                              )}

                              {!isPasswordValid && (
                                <div
                                  className="alert alert-danger text-black"
                                  role="alert"
                                >
                                  {errorMessage}
                                </div>
                              )}

                              {/* Your other UI components go here */}
                            </div>

                            {/* <i className="fas fa-check-circle"></i> */}
                            {/* <i className="fas fa-exclamation-circle"></i> */}
                            {/* <div className="small text-muted mt-1 mb-3 fs-10">
                         Password must be contain atleast 1 Capital charcter ,
                         1 digit , 1 symbol and length min 8 digits
                       </div> */}
                            <small
                              id="passerr"
                              className="text-danger"
                            // style={{ top: "53%" }}
                            ></small>
                          </div>
                          {/* <div className={`form-group`}>
                       <div className="input-group mb-3">
                         <input
                           type="password"
                           className="form-control"
                           name="user_cpassword"
                           id="cpass"
                           required=""
                           value={confPassword}
                           onChange={(e) => setConfPassword(e.target.value)}
                           placeholder="Confirm Password"
                         />
                         <span className="input-group-text">
                           <a
                             href="#view_qr"
                             className=""
                             onClick={(e) => {
                               hsPassword("cpass");
                             }}
                           >
                             <svg
                               xmlns="http://www.w3.org/2000/svg"
                               width="16"
                               height="16"
                               fill="#666"
                               className="bi bi-eye"
                               viewBox="0 0 16 16"
                             >
                               <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                               <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                             </svg>
                           </a>
                         </span>
                       </div>
                       <small id="cpasserr" className="text-danger"></small>
                     </div> */}
                          <div className="form-group referral_code">
                            <a
                              className="referral_code"
                              data-bs-toggle="collapse"
                              data-bs-target="#referral_code"
                              aria-expanded="false"
                            >
                              Referral Code (Optional)
                              <span class="fa-solid fa-chevron-down ms-2"></span>
                              <span class="fa-solid fa-chevron-up ms-2"></span>
                            </a>
                            <div className="collapse mt-2" id="referral_code">
                              <input
                                type="text"
                                className="form-control"
                                name="refercode"
                                id="refercode"
                                required=""
                                value={referral_code}
                                onChange={(e) => setReferral(e.target.value)}
                                placeholder="Referral code"
                              />
                            </div>
                          </div>
                          <div className="d-flex align-items-center mt-2">
                            <input
                              className=""
                              type="checkbox"
                              id="agree_terms"
                              name="agree_terms"
                              checked={agree}
                              onChange={(e) => setAgree(e.target.checked)}
                            />
                            <span className="terms">
                              I agree to {webData.website_title}'s
                              <span className="ms-1 terms_condition">
                                <a
                                  href="/terms_&_conditions"
                                  target="_blank"
                                  className="anchor_link"
                                >
                                  Terms of Service
                                </a>{" "}
                              </span>
                            </span>
                          </div>
                          <div className="d-grid mt-3">
                            <button
                              type="button"
                              id="btn_submit"
                              className="btn login_btn"
                              onClick={(e) => {
                                otpSend(e);
                              }}
                            >
                              {loading ? (
                                <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                              ) : null}
                              <span id="reg">Create Account</span>
                            </button>
                          </div>

                          <ReCAPTCHA
                            style={{ visibility: "none" }}
                            ref={captchaRef}
                            size="invisible"
                            sitekey="6Lf4Y4EpAAAAALSVuf5r_fsG82IEWLxs3I4MwFu6"
                          />

                          {/* <div className="my-2 text-center">OR</div> */}
                        </form>
                        <div className="d-grid my-3">
                          <button
                            className="login-with-google-btn"
                            onClick={() => googleLogin()}
                          >
                            <img
                              src="./img/google.png"
                              width={20}
                              className="me-2"
                            />{" "}
                            Sign in with Google
                          </button>

                          {/* <ConnectButton.Custom>
                         {({
                           account,
                           chain,
                           openAccountModal,
                           openChainModal,
                           openConnectModal,
                           authenticationStatus,
                           mounted
                         }) => {
                           // Note: If your app doesn't use authentication, you
                           // can remove all 'authenticationStatus' checks
                           const ready =
                             mounted && authenticationStatus !== "loading";
                           const connected =
                             ready &&
                             account &&
                             chain &&
                             (!authenticationStatus ||
                               authenticationStatus === "authenticated");

                           return (
                             <div
                               className="d-grid"
                               {...(!ready && {
                                 "aria-hidden": true,
                                 style: {
                                   opacity: 0,
                                   pointerEvents: "none",
                                   userSelect: "none"
                                 }
                               })}
                             >
                               {(() => {
                                 if (!connected) {
                                   return (
                                     <div className="d-grid mt-3 text-center">
                                       <button
                                         class="btn login_btn_outline"
                                         onClick={openConnectModal}
                                         type="button"
                                       >
                                         <i className="fa fa-wallet fs-5 me-2 text-primary"></i>{" "}
                                         Sign in With Wallet
                                       </button>
                                     </div>
                                   );
                                 }

                                 if (chain.unsupported) {
                                   return (
                                     <button
                                       onClick={openChainModal}
                                       type="button"
                                     >
                                       Wrong network
                                     </button>
                                   );
                                 }

                                 return (
                                   <div style={{ display: "flex", gap: 12 }}>
                                     <button
                                       onClick={openChainModal}
                                       style={{
                                         display: "flex",
                                         alignItems: "center"
                                       }}
                                       type="button"
                                     >
                                       {chain.hasIcon && (
                                         <div
                                           style={{
                                             background: chain.iconBackground,
                                             width: 12,
                                             height: 12,
                                             borderRadius: 999,
                                             overflow: "hidden",
                                             marginRight: 4
                                           }}
                                         >
                                           {chain.iconUrl && (
                                             <img
                                               alt={chain.name ?? "Chain icon"}
                                               src={chain.iconUrl}
                                               style={{
                                                 width: 12,
                                                 height: 12
                                               }}
                                             />
                                           )}
                                         </div>
                                       )}
                                       {chain.name}
                                     </button>

                                     <button
                                       onClick={openAccountModal}
                                       type="button"
                                     >
                                       {account.displayName}
                                       {account.displayBalance
                                         ? ` (${account.displayBalance})`
                                         : ""}
                                     </button>
                                   </div>
                                 );
                               })()}
                             </div>
                           );
                         }}
                       </ConnectButton.Custom> */}

                          <div className="col-lg-12">
                            <div className=" text-center text-muted mt-2">
                              Already have an account ?
                              <Link className="text-info " to="/login">
                                {" "}
                                Log In{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
