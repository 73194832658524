import { IoEyeOutline, IoShieldCheckmarkOutline,IoSpeedometer } from "react-icons/io5";
import WhyChooseUsImage from "../../public/Illustration.png";
import { PiUsersThree } from "react-icons/pi";

export default function WhyChooseUS() {
  return (
    <div className="mt-5 padding-bottom">
      <div className="container">
        <div className="row">
          <div className="text-white col order-2 order-lg-1">
            <div className=" h-100 d-flex justify-content-center align-items-center">
              <img src={WhyChooseUsImage} alt="" />
            </div>
          </div>
          <div className="text-white  col-lg order-1 order-lg-1 d-flex flex-column align-items-lg-center justify-content-lg-center">
            <div className="container ">
              <h1 className="fw-bold display-4 mb-4 text-lg-left">
                The Next Generation Blockchain Ecosystem.
              </h1>
              <p className=" text-lg-left fs-5 ">
                At DTBX, we expose you to a world of secure and seamless
                cryptocurrency trading. As a trusted cryptocurrency exchange, we
                prioritize the safety of your assets.
              </p>
            </div>
            <div className="container mt-3">
              <div className="d-flex ">
                <div className="choose-icon margin-right">
                  <IoEyeOutline size={55} className="card-icon-color1" />
                </div>
                <div className="">
                  <h1 className="fw-bold">User-Friendly Interface</h1>
                  <p className="fs-4">
                    Our simple and user-friendly interface is designed to cater
                    to both beginners and experienced traders, providing a
                    seamless and enjoyable trading experience.
                  </p>
                </div>
              </div>
              <div className="d-flex space-y-1">
                <div className="choose-icon margin-right">
                  <IoShieldCheckmarkOutline
                    size={55}
                    className="card-icon-color2"
                  />
                </div>
                <div className="">
                  <h1 className="fw-bold">Security You Can Trust</h1>
                  <p className="fs-4">
                    At DTBX Exchange, your security is our top priority. We
                    employ cutting-edge security measures, including advanced
                    encryption and two-factor authentication, to ensure the
                    safety of your funds and personal information.
                  </p>
                </div>
              </div>
              <div className="d-flex space-y-1 ">
                <div className="choose-icon margin-right">
                  <IoSpeedometer size={55} className="card-icon-color3" />
                </div>
                <div className="">
                  <h1 className="fw-bold">Fastest Transactions</h1>
                  <p className="fs-4">
                    DTBX blockchain leverages advanced technology to ensure that
                    your transactions are executed swiftly and without any
                    hassle.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
