import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import OrderSidebar from "./components/OrderSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import {
  BsBoxArrowInDownLeft,
  BsBoxArrowInDownRight,
  BsBoxArrowInUpRight,
  BsInfoCircleFill
} from "react-icons/bs";
import { PiFolderUserBold } from "react-icons/pi";
import { BiSolidCheckShield } from "react-icons/bi";
import { RiFileList3Line } from "react-icons/ri";
import { TbMessageQuestion } from "react-icons/tb";
import { MdOutlinePayment, MdOutlinePayments } from "react-icons/md";

export default function p2pOrder() {
  return (
    <>
      <Header />
      <div className="page-content">
        <div class="tab_area">
          <div class="container custom_pills">
            <div class="nav nav-pills">
              <a class="nav-item nav-link " href="/FastTrade">
                Fast Trade
              </a>
              <a class="nav-item nav-link active" href="/p2pOrder">
                P2P
              </a>
              <a class="nav-item nav-link" href="/ThirdpartyOrder">
                Third Party
              </a>
            </div>
          </div>
        </div>
        <main className="container mt-5">
          <div className="row">
            <div className="col-lg-3 mb-3">
              <h5 className="mb-3 d-none d-lg-block">P2P</h5>
              <ul
                class="nav nav-pills flex-column verticle-pills"
                id="myTab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <a class="nav-link active" href="#buy_tab" data-toggle="tab">
                    <BsBoxArrowInDownRight className="me-3 fs-4" /> Buy
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" href="#sell_tab" data-toggle="tab">
                    <BsBoxArrowInDownLeft className="me-3 fs-4" /> Sell
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" href="#">
                    <RiFileList3Line className="me-3 fs-4" /> My Orders
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" href="#" data-toggle="tab">
                    <PiFolderUserBold className="me-3 fs-4" /> Become Merchant
                  </a>
                </li>

                <h5 className="my-4 d-none d-lg-block">Other</h5>

                <li class="nav-item" role="presentation">
                  <a class="nav-link" href="#">
                    <TbMessageQuestion className="me-3 fs-4" /> Support
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" href="#payment_tab" data-toggle="tab">
                    <MdOutlinePayments className="me-3 fs-4" /> Payment Setting
                  </a>
                </li>
              </ul>

              <div className="small_card pink_card fs-12 d-lg-block d-none">
                <div className="">
                  <div className="text-dark  fs-14 text_truncate">
                    Please be aware of scams during
                  </div>
                  <div className="text_truncate mb-2">
                    The most popular P2P scams you should be aware
                  </div>
                  <a href="#" className="btn btn-pink btn-sm">
                    Grab Here
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-9 mb-3">
              <div class="tab-content">
                <div class="tab-pane active" id="buy_tab">
                  <div class="custom_pills mb-4">
                    <div class="nav nav-pills" id="nav-tab" role="tablist">
                      <div
                        class="nav-item nav-link active show"
                        href="#USDT"
                        data-toggle="tab"
                      >
                        USDT
                      </div>
                      <div
                        class="nav-item nav-link"
                        href="#btc"
                        data-toggle="tab"
                      >
                        BTC
                      </div>
                    </div>
                  </div>

                  <div class="tab-content">
                    <div class="tab-pane fade active show" id="USDT">
                      <div className="col-lg-12 col-md-12 col-sm-12 mt-3 table-responsive">
                        <table className="table global_table table-hover">
                          <thead>
                            <tr>
                              <th>Merchant/Payment Method</th>
                              <th>Price</th>
                              <th>Available/Limit</th>
                              <th>
                                Trade{" "}
                                <span className="custom_badge bg-soft-success fs-12">
                                  0 Fee(s)
                                </span>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>
                                <div class="d-flex align-items-start">
                                  <div className="circle_backdrop_outline gray_border">
                                    AC
                                  </div>
                                  <div class="product_name">
                                    <div class="mb-0 h6">
                                      DTBX fastest p2p{" "}
                                      <span>
                                        {" "}
                                        <BiSolidCheckShield className="text-green" />{" "}
                                      </span>
                                    </div>
                                    <div class="text-muted fs-12">
                                      {" "}
                                      252 Order(s){" "}
                                      <div className="verticle_line"></div>
                                      94.45%
                                    </div>
                                    <div className="pay_mode">
                                      {" "}
                                      <img src="/img/paytm.png" />{" "}
                                      <img src="/img/upi.png" />{" "}
                                    </div>
                                  </div>{" "}
                                </div>
                              </td>
                              <td>
                                <div>
                                  <span class="desc_card">80</span>
                                  <span class="text-muted ms-1">INR</span>
                                </div>
                              </td>

                              <td>
                                <div class="mb-0 text-lgray">
                                  Available{" "}
                                  <span className="text-dark">
                                    {" "}
                                    111.602733{" "}
                                  </span>
                                  USDT
                                </div>
                                <div class="text-lgray">
                                  {" "}
                                  Limit{" "}
                                  <span className="text-dark"> 1 ~ 2 </span> INR
                                </div>
                              </td>
                              <td>
                                <div>
                                  <a
                                    href="#"
                                    className="btn btn-outline-secondary"
                                  >
                                    Buy
                                  </a>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div class="d-flex align-items-start">
                                  <div className="circle_backdrop_outline gray_border">
                                    AC
                                  </div>
                                  <div class="product_name">
                                    <div class="mb-0 h6">
                                      DTBX fastest p2p{" "}
                                      <span>
                                        {" "}
                                        <BiSolidCheckShield className="text-green" />{" "}
                                      </span>
                                    </div>
                                    <div class="text-muted fs-12">
                                      {" "}
                                      252 Order(s){" "}
                                      <div className="verticle_line"></div>
                                      94.45%
                                    </div>
                                    <div className="pay_mode">
                                      {" "}
                                      <img src="/img/paytm.png" />{" "}
                                      <img src="/img/upi.png" />{" "}
                                    </div>
                                  </div>{" "}
                                </div>
                              </td>
                              <td>
                                <div>
                                  <span class="desc_card">90</span>
                                  <span class="text-muted ms-1">INR</span>
                                </div>
                              </td>

                              <td>
                                <div class="mb-0 text-lgray">
                                  Available{" "}
                                  <span className="text-dark">
                                    {" "}
                                    111.602733{" "}
                                  </span>
                                  USDT
                                </div>
                                <div class="text-lgray">
                                  {" "}
                                  Limit{" "}
                                  <span className="text-dark"> 1 ~ 2 </span> INR
                                </div>
                              </td>
                              <td>
                                <div>
                                  <a
                                    href="#"
                                    className="btn btn-outline-secondary"
                                  >
                                    Buy
                                  </a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-start">
                                  <div className="circle_backdrop_outline gray_border">
                                    AC
                                  </div>
                                  <div class="product_name">
                                    <div class="mb-0 h6">
                                      DTBX fastest p2p{" "}
                                      <span>
                                        {" "}
                                        <BiSolidCheckShield className="text-green" />{" "}
                                      </span>
                                    </div>
                                    <div class="text-muted fs-12">
                                      {" "}
                                      252 Order(s){" "}
                                      <div className="verticle_line"></div>
                                      94.45%
                                    </div>
                                    <div className="pay_mode">
                                      {" "}
                                      <img src="/img/paytm.png" />{" "}
                                      <img src="/img/upi.png" />{" "}
                                    </div>
                                  </div>{" "}
                                </div>
                              </td>
                              <td>
                                <div>
                                  <span class="desc_card">89.99</span>
                                  <span class="text-muted ms-1">INR</span>
                                </div>
                              </td>

                              <td>
                                <div class="mb-0 text-lgray">
                                  Available{" "}
                                  <span className="text-dark">
                                    {" "}
                                    111.602733{" "}
                                  </span>
                                  USDT
                                </div>
                                <div class="text-lgray">
                                  {" "}
                                  Limit{" "}
                                  <span className="text-dark"> 1 ~ 2 </span> INR
                                </div>
                              </td>
                              <td>
                                <div>
                                  <a
                                    href="#"
                                    className="btn btn-outline-secondary"
                                  >
                                    Buy
                                  </a>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="btc">
                      asdasdaADdADAdaasas
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane"
                  id="sell_tab"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div class="custom_pills mb-4">
                    <div class="nav nav-pills" id="nav-tab" role="tablist">
                      <div
                        class="nav-item nav-link active show"
                        href="#USDT"
                        data-toggle="tab"
                      >
                        USDT
                      </div>
                      <div
                        class="nav-item nav-link"
                        href="#btc"
                        data-toggle="tab"
                      >
                        ETC
                      </div>
                    </div>
                  </div>

                  <div class="tab-content">
                    <div class="tab-pane fade active show" id="USDT">
                      <div className="col-lg-12 col-md-12 col-sm-12 mt-3 table-responsive">
                        <table className="table global_table table-hover">
                          <thead>
                            <tr>
                              <th>Merchant/Payment Method</th>
                              <th>Price</th>
                              <th>Available/Limit</th>
                              <th>
                                Trade{" "}
                                <span className="custom_badge bg-soft-success fs-12">
                                  0 Fee(s)
                                </span>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>
                                <div class="d-flex align-items-start">
                                  <div className="circle_backdrop_outline gray_border">
                                    AC
                                  </div>
                                  <div class="product_name">
                                    <div class="mb-0 h6">
                                      DTBX fastest p2p{" "}
                                      <span>
                                        {" "}
                                        <BiSolidCheckShield className="text-green" />{" "}
                                      </span>
                                    </div>
                                    <div class="text-muted fs-12">
                                      {" "}
                                      252 Order(s){" "}
                                      <div className="verticle_line"></div>
                                      94.45%
                                    </div>
                                    <div className="pay_mode">
                                      {" "}
                                      <img src="/img/paytm.png" />{" "}
                                      <img src="/img/upi.png" />{" "}
                                    </div>
                                  </div>{" "}
                                </div>
                              </td>
                              <td>
                                <div>
                                  <span class="desc_card">80</span>
                                  <span class="text-muted ms-1">INR</span>
                                </div>
                              </td>

                              <td>
                                <div class="mb-0 text-lgray">
                                  Available{" "}
                                  <span className="text-dark">
                                    {" "}
                                    111.602733{" "}
                                  </span>
                                  USDT
                                </div>
                                <div class="text-lgray">
                                  {" "}
                                  Limit{" "}
                                  <span className="text-dark"> 1 ~ 2 </span> INR
                                </div>
                              </td>
                              <td>
                                <div>
                                  <a
                                    href="#"
                                    className="btn btn-outline-secondary"
                                  >
                                    Buy
                                  </a>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div class="d-flex align-items-start">
                                  <div className="circle_backdrop_outline gray_border">
                                    AC
                                  </div>
                                  <div class="product_name">
                                    <div class="mb-0 h6">
                                      DTBX fastest p2p{" "}
                                      <span>
                                        {" "}
                                        <BiSolidCheckShield className="text-green" />{" "}
                                      </span>
                                    </div>
                                    <div class="text-muted fs-12">
                                      {" "}
                                      252 Order(s){" "}
                                      <div className="verticle_line"></div>
                                      94.45%
                                    </div>
                                    <div className="pay_mode">
                                      {" "}
                                      <img src="/img/paytm.png" />{" "}
                                      <img src="/img/upi.png" />{" "}
                                    </div>
                                  </div>{" "}
                                </div>
                              </td>
                              <td>
                                <div>
                                  <span class="desc_card">90</span>
                                  <span class="text-muted ms-1">INR</span>
                                </div>
                              </td>

                              <td>
                                <div class="mb-0 text-lgray">
                                  Available{" "}
                                  <span className="text-dark">
                                    {" "}
                                    111.602733{" "}
                                  </span>
                                  USDT
                                </div>
                                <div class="text-lgray">
                                  {" "}
                                  Limit{" "}
                                  <span className="text-dark"> 1 ~ 2 </span> INR
                                </div>
                              </td>
                              <td>
                                <div>
                                  <a
                                    href="#"
                                    className="btn btn-outline-secondary"
                                  >
                                    Buy
                                  </a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-start">
                                  <div className="circle_backdrop_outline gray_border">
                                    AC
                                  </div>
                                  <div class="product_name">
                                    <div class="mb-0 h6">
                                      DTBX fastest p2p{" "}
                                      <span>
                                        {" "}
                                        <BiSolidCheckShield className="text-green" />{" "}
                                      </span>
                                    </div>
                                    <div class="text-muted fs-12">
                                      {" "}
                                      252 Order(s){" "}
                                      <div className="verticle_line"></div>
                                      94.45%
                                    </div>
                                    <div className="pay_mode">
                                      {" "}
                                      <img src="/img/paytm.png" />{" "}
                                      <img src="/img/upi.png" />{" "}
                                    </div>
                                  </div>{" "}
                                </div>
                              </td>
                              <td>
                                <div>
                                  <span class="desc_card">89.99</span>
                                  <span class="text-muted ms-1">INR</span>
                                </div>
                              </td>

                              <td>
                                <div class="mb-0 text-lgray">
                                  Available{" "}
                                  <span className="text-dark">
                                    {" "}
                                    111.602733{" "}
                                  </span>
                                  USDT
                                </div>
                                <div class="text-lgray">
                                  {" "}
                                  Limit{" "}
                                  <span className="text-dark"> 1 ~ 2 </span> INR
                                </div>
                              </td>
                              <td>
                                <div>
                                  <a
                                    href="#"
                                    className="btn btn-outline-secondary"
                                  >
                                    Buy
                                  </a>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="btc">
                      asdasdaADdADAdaasas
                    </div>
                  </div>
                </div>

                <div class="tab-pane" id="payment_tab">
                  <h4 className="mb-4">
                    Payment Settings
                    <span className="text-lgray fs-12 ms-2">(Maximum: 10)</span>
                  </h4>
                  <div className="alert alert-warning">
                    Select atleast one payement method
                  </div>

                  <div className="text-center border-bottom-0 text-muted">
                    <img src="/img/no-data.png" className="no-data-found" />
                    <p> Payement Method Not Found</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
