import React, { useEffect, useState } from "react";
import Header from "./HomeComp/Header";
import ProfileSidebar from "./components/ProfileSidebar";
import // apiCreateToken,
// deleteALLApi,
// deleteCreateApi,
// getCreateAPI,
// N_getUserProfile,
"./redux/helpers/api_functions_new";
import { useSelector } from "react-redux";
import Loader from "./components/Loader";
import { Link } from "react-router-dom";
import { AiOutlineEdit, AiOutlineFileText } from "react-icons/ai";
import { BsShieldCheck } from "react-icons/bs";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FBTCins, FaSortDown, FaSortUp } from "react-icons/fa";
import { Sparklines, SparklinesLine } from "react-sparklines";
import Footer from "./HomeComp/Footer";
import { async } from "react-advanced-form";
import { NotificationManager } from "react-notifications";
import {
  N_GetAllSymbol,
  N_createApiKey,
  N_deleteAPIkey,
  N_deleteAPIkeys,
  N_getCreateAPI,
} from "./redux/helpers/api_functions_new";

export default function Api(props) {
  const { wallet } = useSelector((state) => state.coinDBReducer);
  const [wallets, setWallets] = useState();
  const { isLoggedIn, user, profile } = useSelector(
    (state) => state.AuthReducer
  );
  const [coindata, setCoinData] = useState();
  useEffect(() => {
    // N_getBlockchain().then((res) => {
    //   if (res.status == 200) {
    //     setBlockchain(res.data);
    //   }
    // });
    N_GetAllSymbol().then((res) => {
      setWallets(res);
      const coinsData = res.find((item) => item.symbol == "USDT");
      setCoinData(coinsData);
    });
  }, []);
  const token = user?.params ? user.params.token : user.token;
  const [apiName, setApiName] = React.useState("");
  const [createApiTab, setCreateApiTab] = React.useState(false);
  const [refData, setRefData] = React.useState(false);
  const [selectedTradingPair1, setSelectedTradingPair1] = useState(""); // Set your initial value here
  const [selectedTradingPair2, setSelectedTradingPair2] = useState(""); // Set your initial value here

  const [getApiData, setGetApiData] = React.useState([]);
  const [currentData, setCurrentData] = React.useState("");

  const [enableCheck, setEnableCheck] = React.useState({
    sell_trading: false,
    buy_trading: false,
    user_info: false,
  });

  // const { user } = useSelector((state) => state.AuthReducer);
  // const [loading, setLoading] = React.useState(true);
  // const [profile, setProfile] = React.useState({});
  // useEffect(() => {
  //   N_getUserProfile(user?.params ? user.params.user_id : user.user_id)
  //     .then((d) => {
  //       // console.log("userProfile",d?.params.profile_info);
  //       if (d.status === 200) {
  //         setProfile(d?.params.profile_info);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }, []);

  async function CreateApi() {
    const body = {
      api_name: apiName,
      user_info_access: enableCheck.user_info,
      sell_access: enableCheck.sell_trading,
      buy_access: enableCheck.buy_trading,
    };

    N_createApiKey(body, token).then((res) => {
      if (res.status === 200) {
        setCurrentData(res.result);
        NotificationManager.success(res.message);
      }
      if (res.status == 400) {
        NotificationManager.error(res.message);
      }
    });
  }

  async function deleteAPI(api_key) {
    if (token && api_key) {
      N_deleteAPIkey(api_key, token).then((res) => {
        if (res.status == 200) {
          NotificationManager.success(res.message);
          window.location.reload();
        }
        if (res.status == 400) {
          NotificationManager.error(res.message);
        }
      });
    }
  }

  async function deleteAPIALL(delete_all) {
    if (token && delete_all) {
      N_deleteAPIkeys(delete_all, token).then((res) => {
        console.log(res, "res::deleteCreateApi123");
        if (res.status == 200) {
          NotificationManager.success(res.message);
          window.location.reload();
        }
        if (res.status == 400) {
          NotificationManager.error(res.message);
        }
      });
    }
  }
  useEffect(() => {
    N_getCreateAPI(token).then((res) => {
      setGetApiData(res.data);
    });
  }, [user, refData]);
  return (
    <>
      <Header {...props} />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <ProfileSidebar />
        </div>

        <main className="s-layout__content">
          <div className="dashboard_header">
            <h3 className="header_title">Api Management</h3>
            <div className="flex_row">
              <div
                className="btn btn-pink rounded-pill me-2"
                onClick={() => {
                  setCreateApiTab(true);
                }}
              >
                Creat API
              </div>
              <div>
                {" "}
                <a href="" className="btn btn-outline-pink rounded-pill me-2">
                  Withdraw to API Address Book
                </a>{" "}
              </div>

              <div>
                {" "}
                <a href="" className="btn btn-outline-pink rounded-pill">
                  Abnormal API Call Notifications
                </a>
              </div>
            </div>
          </div>
          <div className="account_page container">
            <div className="col-lg-12 col-md-12 col-sm-12 mt-3 ">
              <div className="text-center border-bottom-0">
                <div class="row">
                  <div class="col-md-6 offset-md-3">
                    {createApiTab == true ? (
                      <div className=" rounded-3 bg-dark p-4">
                        {currentData != "" ? (
                          <>
                            <div className="d-flex align-items-center">
                              <div>
                                <span class="input-group-text bg-transparent me-2">
                                  <div class="">
                                    <i class="fa fa-lock text-lgray"></i>
                                  </div>
                                </span>
                              </div>
                              <h4 className="bold">{currentData?.api_name}</h4>
                            </div>
                            <div className="text-start pt-3">
                              <p className="text-dgray fs-12 m-0 pb-2">
                                setting.api_settings.api_key
                              </p>
                              <p className="text-dark fs-5 p-0 m-0">
                                {currentData?.api_key}
                              </p>
                            </div>
                            <div className="text-start pt-3">
                              <p className="text-dgray fs-12 m-0 pb-2">
                                setting.api_settings.secret_key
                              </p>
                              <p className="text-dark fs-5 p-0 m-0">
                                {currentData?.secret_key}
                              </p>
                            </div>
                            <div className="text-start pt-3">
                              <p className="text-dgray fs-12 m-0 pb-2">
                                API restrictions:
                              </p>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDisabled"
                                  checked={currentData.is_sell_trade}
                                  disabled
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDisabled"
                                >
                                  Enable sell trading
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDisabled"
                                  checked={currentData.is_buy_trade}
                                  disabled
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDisabled"
                                >
                                  Enable buy trading
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckCheckedDisabled"
                                  checked={currentData.is_user_Info}
                                  disabled
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckCheckedDisabled"
                                >
                                  Enable user balance info
                                </label>
                              </div>
                            </div>
                            <div className="d-flex pt-3">
                              <div
                                className="btn btn-success w-100 m-1"
                                onClick={() => {
                                  setRefData(true);
                                  setCreateApiTab(false);
                                }}
                              >
                                I have saved the API Secret properly
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="d-flex align-items-center">
                              <span class="input-group-text bg-transparent me-2">
                                <div class="">
                                  <i class="fa fa-lock text-lgray"></i>
                                </div>
                              </span>
                              <div class="input-group ">
                                <input
                                  type="text"
                                  class="form-control form-control-sm text-start mp-exide-form bg-white w-50"
                                  aria-label="Text input with checkbox"
                                  placeholder="Name "
                                  onChange={(e) => {
                                    setApiName(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="text-start pt-3">
                              <p className="text-dgray fs-12 m-0 pb-2">
                                API restrictions:
                              </p>
                            </div>
                            <div className="text-start mt-4">
                              <div class="form-check form-check ">
                                <input
                                  class="form-check-input pointer"
                                  type="checkbox"
                                  id="inlineCheckbox1"
                                  value="Enable trading"
                                  onChange={(e) => {
                                    setEnableCheck({
                                      ...enableCheck,
                                      sell_trading: e.target.checked,
                                    });
                                  }}
                                />

                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox1"
                                >
                                  Enable sell trading
                                </label>
                              </div>
                              <div class="form-check form-check ">
                                <input
                                  class="form-check-input pointer"
                                  type="checkbox"
                                  id="inlineCheckbox1"
                                  value="Enable trading"
                                  onChange={(e) => {
                                    setEnableCheck({
                                      ...enableCheck,
                                      buy_trading: e.target.checked,
                                    });
                                  }}
                                />

                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox1"
                                >
                                  Enable buy trading
                                </label>
                              </div>
                              <div class="form-check form-check ">
                                <input
                                  class="form-check-input pointer"
                                  type="checkbox"
                                  id="inlineCheckbox2"
                                  value="Enable user info"
                                  onChange={(e) => {
                                    setEnableCheck({
                                      ...enableCheck,
                                      user_info: e.target.checked,
                                    });
                                  }}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox2"
                                >
                                  Enable user info
                                </label>
                              </div>
                              {/* <div class="form-check form-check ">
                            <input
                              class="form-check-input pointer"
                              type="checkbox"
                              id="inlineCheckbox3"
                              value="Enable withdrawals"
                              onChange={(e) => {
                                setEnableCheck({
                                  ...enableCheck,
                                  withdrawals: e.target.checked,
                                });
                              }}
                            />
                            <label
                              class="form-check-label"
                              for="inlineCheckbox3"
                            >
                              Enable withdrawals
                            </label>
                          </div> */}
                            </div>

                            <div className="d-flex pt-3">
                              <div
                                className="btn btn-light w-100 m-1"
                                onClick={() => {
                                  setCreateApiTab(false);
                                }}
                              >
                                Cancel
                              </div>
                              <div
                                className="btn btn-success w-100 m-1"
                                onClick={() => {
                                  CreateApi();
                                }}
                              >
                                Save
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div>
                        <div className="d-flex align-content-center justify-content-between fw-bold fs-5 pb-3 p-2">
                          <div>API list</div>
                          <div
                            className="d-flex align-items-center text-danger pointer"
                            onClick={() => {
                              const delete_all = true;
                              deleteAPIALL(delete_all);
                            }}
                          >
                            <div class="me-1">
                              <i class="fa fa-trash text-danger"></i>
                            </div>
                            <div>Delete all API</div>
                          </div>
                        </div>
                        {getApiData &&
                          getApiData.map((item, i) => {
                            return (
                              <>
                                <div className="m-2">
                                  <div className=" border rounded-3 bg-dark p-4">
                                    <div className="d-flex align-items-center">
                                      <div>
                                        <span class="input-group-text bg-transparent me-2">
                                          <div class="">
                                            <i class="fa fa-lock text-lgray"></i>
                                          </div>
                                        </span>
                                      </div>
                                      <h4 className="bold">{item.api_name}</h4>
                                    </div>
                                    <div className="text-start pt-3">
                                      <p className="text-dgray fs-12 m-0 pb-2">
                                        setting.api_settings.api_key
                                      </p>
                                      <p className="text-dark fs-5 p-0 m-0">
                                        {item.api_key}
                                      </p>
                                    </div>
                                    <div className="text-start pt-3">
                                      <p className="text-dgray fs-12 m-0 pb-2">
                                        setting.api_settings.secret_key
                                      </p>
                                      <p className="text-dark fs-5 p-0 m-0">
                                        **********
                                      </p>
                                    </div>
                                    <div className="text-start pt-3">
                                      <p className="text-dgray fs-12 m-0 pb-2">
                                        API restrictions:
                                      </p>
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="flexCheckDisabled"
                                          checked={item.sell_access}
                                          disabled
                                        />
                                        <label
                                          class="form-check-label"
                                          for="flexCheckDisabled"
                                        >
                                          Enable sell trading
                                        </label>
                                      </div>
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="flexCheckDisabled"
                                          checked={item.buy_access}
                                          disabled
                                        />
                                        <label
                                          class="form-check-label"
                                          for="flexCheckDisabled"
                                        >
                                          Enable buy trading
                                        </label>
                                      </div>
                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="flexCheckCheckedDisabled"
                                          checked={item.user_info_access}
                                          disabled
                                        />
                                        <label
                                          class="form-check-label"
                                          for="flexCheckCheckedDisabled"
                                        >
                                          Enable user info
                                        </label>
                                      </div>
                                      {/* <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckCheckedDisabled"
                                        checked={item.is_withdrawal}
                                        disabled
                                      />
                                      <label
                                        class="form-check-label"
                                        for="flexCheckCheckedDisabled"
                                      >
                                        Enable withdrawals
                                      </label>
                                    </div> */}
                                    </div>

                                    <div className="pt-3">
                                      <div
                                        className="btn btn-danger w-100"
                                        onClick={() => {
                                          deleteAPI(item.api_key);
                                        }}
                                      >
                                        Delete
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </div>

                {/* <img src="/img/no-data.png" className="no-data-found" /> */}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
