import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FaCoins, FaSortDown, FaSortUp } from "react-icons/fa";
import {
  N_GetAllSymbol,
  N_createAddress,
  N_getBlockchain,
  N_getBlockchainData,
  N_depositHistory,
  N_DepositUpdate,
} from "./redux/helpers/api_functions_new";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "./redux/helpers/helper_functions";
import { Helmet } from "react-helmet";

export default function AssetsDeposit() {
  const [blockchain, setBlockchain] = useState();
  const [currency, setCurrency] = useState();
  const [contract, setContract] = useState();
  const [coindata, setCoinData] = useState();
  const [wallets, setWallets] = useState();
  const [deposithistory, setDepositHistory] = useState();
  const [symbolData, setSymbolData] = useState();
  const [isSelected, setIsSelected] = useState(0);
  const [search, setSearch] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const { user } = useSelector((state) => state.AuthReducer);

  useEffect(() => {
    N_DepositUpdate(user?.params ? user.params.token : user.token);
  }, []);

  useEffect(() => {
    // N_getBlockchain().then((res) => {
    //   if (res.status == 200) {
    //     setBlockchain(res.data);
    //   }
    // });
    N_GetAllSymbol().then((res) => {
      setWallets(res);
      const coinsData = res.find((item) => item.symbol == "USDT");
      setCoinData(coinsData);
      setBlockchain(coinsData.contractAddress);
    });
  }, []);
  useEffect(() => {
    if (coindata) {
      const token = user?.params ? user.params.token : user.token;
      let data = {
        symbol: coindata.symbol,
      };
      N_depositHistory(data, token)
        .then((data) => {
          if (data.status === 200) {
            setDepositHistory(data?.result);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [coindata]);
  const walletsdata =
    wallets &&
    wallets.filter((product) => {
      return product.symbol.includes(search);
    });
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    NotificationManager.success("Copied");
  };
  const buttonStyle = {
    cursor: "pointer",
  };
  return (
    <>
      <div className="application">
        <Helmet>
          <meta
            name="description"
            content="Safely deposit your assets on DTBX Exchange. We prioritize security and
efficiency for a seamless crypto trading experience."
          />
          <title>Secure Asset Deposits: DTBX Exchange </title>
        </Helmet>
      </div>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="container">
            <div className="marginTop">
              <div className="account_page">
                <div className="flex_row mb-4">
                  <div>
                    <h3 className=""> Deposit Crypto </h3>
                  </div>

                  <div>
                    <Link to="/AssetsFiat" className="btn btn-light">
                      Deposit Fiat{" "}
                      <i class="fa-solid fa-arrow-right-long ms-1"></i>
                    </Link>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-7">
                    <div className="cstm_form">
                      <div class="row_flex_div ">
                        <div class="col_left"></div>
                        <div class="col_right">
                          <label for="inputPassword6" class="">
                            Coin
                          </label>
                        </div>
                      </div>

                      <div class="row_flex_div mb-4">
                        <div class="col_left">
                          <label for="inputPassword6" class="">
                            Select Coin
                          </label>
                        </div>
                        <div class="col_right">
                          <div className="select_box">
                            {" "}
                            <div
                              className="select_box_inner"
                              data-bs-toggle="modal"
                              data-bs-target="#coin_modal"
                            >
                              <div class="d-flex align-items-center">
                                <div>
                                  <img
                                    src={coindata?.icon}
                                    alt="coin symbole"
                                    class="coin_img me-2"
                                  />
                                </div>
                                <div class="product_name">
                                  {coindata?.symbol}{" "}
                                  <span className="unit text-muted">
                                    {coindata?.name}
                                  </span>
                                </div>{" "}
                              </div>
                            </div>
                            <div>
                              {" "}
                              <img
                                src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/arrow.267b17d2.svg"
                                class="arrow_img"
                                alt="arrow-icon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row_flex_div">
                        <div class="col_left"></div>
                        <div class="col_right">
                          <label for="inputPassword6" class="">
                            Network
                          </label>
                        </div>
                      </div>
                      <div class="row_flex_div mb-4">
                        <div class="col_left">
                          <label for="inputPassword6" class="form-label">
                            Select Network
                          </label>
                        </div>
                        <div class="col_right">
                          <div className="select_box">
                            {" "}
                            <div className="select_box_inner">
                              <div
                                className="select_box_inner"
                                data-bs-toggle="modal"
                                data-bs-target="#network_modal"
                              >
                                <div class="product_name">
                                  {symbolData
                                    ? symbolData.symbol +
                                    " (" +
                                    symbolData.contract_type.toUpperCase() +
                                    ")" +
                                    capitalizeFirstLetter(
                                      symbolData.blockchain
                                    )
                                    : "Select Network"}
                                </div>{" "}
                              </div>
                            </div>
                            <div>
                              {" "}
                              <img
                                src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/arrow.267b17d2.svg"
                                class="arrow_img"
                                alt="arrow-icon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {isSelected == 1 ? (
                        <div class="row_flex_div mb-4">
                          <div class="col_left"></div>
                          <div class="col_right">
                            <h5>
                              <small className="text-lgray">Wallet Address</small>
                            </h5>
                            <h5>
                              {currency?.wallet_address}
                              {/* <a href="#">
                                  {" "}
                                  <img src="/img/copy-icon.svg" />
                                </a>  */}
                              <span href="" className="anchor_link">
                                <i
                                  className="fa fa-copy ms-2"
                                  onClick={() => {
                                    copyToClipboard(
                                      currency?.wallet_address
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </span>{" "}
                            </h5>
                            <div className="row">
                              <div className="col-3 m-auto">
                                <div className="custom_card p-2 shadow">
                                  <div className="card-body">
                                    <a href="">
                                      {" "}
                                      <img
                                        src={`https://api.qrserver.com/v1/create-qr-code/?data=${currency?.wallet_address}&amp;size=50x50`}
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="mt-4">
                        <small className="text-lgray">Recipient Account</small>
                        <p>Funding Account (Main)</p>
                      </div> */}
                            {/* <div className="mt-4">
                        <div className="d-flex justify-content-between">
                          <div>
                            <small className="text-lgray">
                              Deposit Confirmation
                            </small>
                            <p>
                              3 <span className="text-lgray">Block(s)</span>{" "}
                            </p>
                          </div>
                          <div>
                            <small className="text-lgray">
                              Withdrawal Confirmation
                            </small>
                            <p>
                              3 <span className="text-lgray">Block(s)</span>{" "}
                            </p>
                          </div>
                        </div>
                      </div> */}
                            {/* {isSelected == 1 && contract?.contract_address ? (
                              <>
                                <div className="mt-4">
                                  <div>
                                    <small className="text-lgray">
                                      Contact Address
                                    </small>
                                    <p>
                                      {contract?.contract_address}{" "}
                                      <span href="" className="anchor_link">
                                        <i
                                          className="fa fa-copy ms-2"
                                          onClick={() => {
                                            copyToClipboard(
                                              contract.contract_address
                                            );
                                          }}
                                          style={{ cursor: "pointer" }}
                                        ></i>
                                      </span>{" "}
                                    </p>
                                  </div>
                                </div>
                                <p className="text-lgray fs-12">
                                  Confirm that your network is{" "}
                                  <span className="text-primary">
                                    {contract?.blockchain}
                                  </span>
                                  .
                                </p>
                              </>
                            ) : null} */}
                          </div>
                        </div>
                      ) : isSelected == 3 ? (
                        <div class="row_flex_div mb-4">
                          <div class="col_left"></div>
                          <div class="col_right">
                            <h5>
                              <small className="text-lgray">
                                Deposits are temporarily unavailable. Any
                                pending deposit orders will be processed as soon
                                as possible after deposits come back online.
                              </small>
                            </h5>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-4">
                    <h5>FAQs</h5>

                    <div class="lrtcss-ifarjv">
                      <p class="d-flex">
                        <div>
                          {" "}
                          <i class="fa-regular fa-rectangle-list me-2 fs-12 text-lgray"></i>
                        </div>
                        <div>
                          {" "}
                          <a class="text-lgray" href="#">
                            {" "}
                            How do I deposit crypto into my Aconomic account?{" "}
                          </a>
                        </div>
                      </p>
                      <p class="d-flex">
                        <div>
                          {" "}
                          <i class="fa-regular fa-rectangle-list me-2 text-lgray fs-12"></i>
                        </div>
                        <div>
                          {" "}
                          <a class="text-lgray" href="#">
                            {" "}
                            What should I do if I didn't receive my deposits or
                            I deposit to an incorrect address?{" "}
                          </a>
                        </div>
                      </p>
                      <p class="d-flex">
                        <div>
                          {" "}
                          <i class="fa-regular fa-rectangle-list me-2 text-lgray fs-12"></i>
                        </div>
                        <div>
                          {" "}
                          <a class="text-lgray" href="#">
                            What should I do if I deposit the wrong crypto?{" "}
                          </a>
                        </div>
                      </p>
                      <p class="d-flex">
                        <div>
                          {" "}
                          <i class="fa-regular fa-rectangle-list me-2 text-lgray fs-12"></i>
                        </div>
                        <div>
                          {" "}
                          <a class="text-lgray" href="#">
                            What should I do if I forgot to specify the Memo,
                            Tag, or Message for my deposit?
                          </a>
                        </div>
                      </p>
                      <p class="d-flex">
                        <div>
                          {" "}
                          <i class="fa-regular fa-rectangle-list me-2 text-lgray fs-12"></i>
                        </div>
                        <div>
                          {" "}
                          <a class="text-lgray" href="#">
                            {" "}
                            What should I do if I mistakenly deposit through the
                            BSC or BEP20 network and did not receive the
                            deposit?
                          </a>
                        </div>
                      </p>
                      <p class="d-flex">
                        <div>
                          {" "}
                          <i class="fa-regular fa-rectangle-list me-2 text-lgray fs-12"></i>
                        </div>
                        <div>
                          {" "}
                          <a class="text-lgray" href="#">
                            What are the common deposit networks?
                          </a>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="flex_row mb-4">
                    <div className="">
                      <div class="">
                        <h3 className=""> Deposit History </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="overflow-auto mt-3">
                  <div className="table_scroll_div">
                    <table className="table global_table">
                      <thead>
                        <tr>
                          <th className="text-end">
                            <div className="d-flex align-items-center">
                              <span className="">Symbol</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="">Amount</div>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">Address</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className=""> Status</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>

                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">Time</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {deposithistory && deposithistory.length > 0 ? (
                          deposithistory.map((item) => {
                            return (
                              <tr>
                                <td>{item?.symbol}</td>
                                <td class="text-end">{item.amount}</td>
                                <td class="text-end">{item.to_address}</td>
                                <td class="text-end">
                                  {item.status === true ? "success" : ""}
                                </td>

                                <td class="text-end">
                                  {new Date(item.createdAt).toLocaleString()}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              <img
                                src="/img/no-data.png"
                                className="no-data-found"
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </main>
      </div>

      <div class="modal coin_modal fade" tabindex="-1" id="coin_modal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
              <h4 class="modal-title">Coin</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="m-1">
                <div class="input-group ">
                  <span class="input-group-text bg-transparent">
                    <div class="">
                      <i class="fa fa-search text-lgray"></i>
                    </div>
                  </span>
                  <input
                    type="text"
                    class="form-control text-start mp-exide-form bg-white"
                    aria-label="Text input with checkbox"
                    placeholder="Search "
                    onChange={(e) => {
                      setSearch(e.target.value.toUpperCase());
                    }}
                  />
                </div>
              </div>

              <div className="mt-4">
                <h6 className="text-lgray">Popular</h6>
                <p class="fs-14">
                  {wallets &&
                    wallets.map((item) => {
                      if (
                        item.symbol == "USDT" ||
                        item.symbol == "ETH" ||
                        item.symbol == "BNB" ||
                        item.symbol == "TRX"
                      ) {
                        return (
                          <span
                            class="label_light me-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                              setCoinData(item);
                              setIsSelected(false);
                              setSymbolData();
                            }}
                          >
                            {item.symbol}
                          </span>
                        );
                      }
                    })}
                </p>
              </div>

              <div className="custom_scrollbar height250">
                {walletsdata &&
                  walletsdata.map((item) => {
                    if (item.symbol !== "INR") {
                      return (
                        <div
                          class="select_options"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            setCoinData(item);
                            setIsSelected(false);
                            setSymbolData("");
                            setBlockchain(item.contractAddress);
                          }}
                        >
                          <div>
                            <img
                              src={item.icon}
                              alt="coin symbole"
                              class="market_coin_img"
                            />
                          </div>
                          <div class="product_name">
                            <div class="mb-0 fw-bold">{item.symbol}</div>
                            <div class="text-muted fs-12">{item.name}</div>
                          </div>{" "}
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal coin_modal fade" tabindex="-1" id="network_modal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
              <h4 class="modal-title">Select Network</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="alert alert-warning">
                <div class="d-flex">
                  <div>
                    <i className="fa fa-exclamation-circle me-2 text-warning"></i>
                  </div>
                  <div>
                    Ensure that the selected deposit network is the same as the
                    withdrawal network. Otherwise, your assets could be lost.
                  </div>
                </div>
              </div>

              <div className="custom_scrollbar height275">
                {blockchain &&
                  blockchain.map((item) => {
                    return (
                      <div
                        class="select_options"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          // N_createAddress(
                          //   user?.params ? user.params.token : user.token,
                          //   coindata?.symbol
                          // );
                          setSymbolData(item);
                          N_getBlockchainData(
                            item?.blockchain,
                            user?.params ? user.params.token : user.token,
                            coindata?.symbol
                          ).then((res) => {
                            if (res.status == 200) {
                              if (item.is_deposit) {
                                setCurrency(res.currencydata);
                                setContract(res.contractdata);
                                setIsSelected(1);
                                // } else if (
                                //   item.blockchain == coindata?.blockchain
                                // ) {
                                //   setCurrency(res.currencydata);
                                //   setIsSelected(2);
                                // } else {
                              } else {
                                setIsSelected(3);
                              }
                            }
                          });
                        }}
                      >
                        <div class="product_name">
                          <div class="mb-0 fw-bold">
                            {item.symbol}
                            {item.contract_type == "erc20"
                              ? "(ERC20)"
                              : item.contract_type == "trc20"
                                ? "(TRC20)"
                                : item.contract_type == "bep20"
                                  ? " Smart Chain(BEP20)"
                                  : item.contract_type == "dtbx20"
                                    ? "(DTBX20)"
                                    : ""}
                          </div>
                          <div class="text-muted fs-12"> {item.blockchain}</div>
                        </div>{" "}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
